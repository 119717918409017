.messaging-member{
    $self: &;
    
    &--selected{
        @include media-min-width(medium){
            & #{$self}__wrapper{
                background-color: white;       
            }
        } 
    }
    &--unread{
        & #{$self}__name{
            font-weight: bold;
            color: darken($colour-title, 15%);
        }
        & #{$self}__message{
            color: darken($colour-text, 15%);
            font-weight: bold;
        }
    }
    &--online{
        & .user-status{
            background-color: lighten($colour-fourth, 20%);
        }
    }

    &__wrapper{
        cursor: pointer;
        margin-bottom: 1rem;
        padding: 0.5rem 1rem;
        border-radius: $form-radius;
        
        display: grid;
        grid-template-columns: 4rem 4fr;
        grid-template-rows: 2rem 2rem;
        column-gap: 1rem;
        grid-template-areas: 
        "avatar     name"
        "avatar     message";

        @include media(large){
            grid-template-columns: 3.5rem 1fr;
            grid-template-rows: 1.75rem 1.75rem;
        }
    }
    &__avatar{
        grid-area: avatar;
        position: relative;

        & img {
            border-radius: 50%;
            width: 100%;
        }
    }

    &__name{
        grid-area: name;
        color: $colour-title;
        margin-top: auto;
        @include chat-rfs;
    }
    &__message{
        grid-area: message;
        white-space: nowrap;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        @include small-text-rfs;
    }
}