#bloc-reset-password .inscription-page__header {
  background-color: white;
  margin-top: 15px;
}

.corp {
  background-color: white;
}

.message_1 {
  font-size: 40px;
  font-family: "Faune Text";
  font-weight: bold;
}

#felicitation {
  font-weight: bold;
}

.message_3 {
  font-family: "Faune Text";
  font-weight: bold;
}

#nom_utiliasteur {
  font-size: 28px;
  color: #ff8c42;
}

#remerciement {
  font-size: 24px;
}

.message_2 {
  font-size: 18px;
}

.btn_orange {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #ff8c42;
  color: #ffffff;
}

.message_4 {
  font-size: 14px;
}

.corp {
  //background-image: url(base-url('../images/bg/bg_notification_large.png'));
  background-size: 100% 100%;
}

@media (min-width: 1280px) {
  .corp {
    //background-image: url(base-url("../images/bg/bg_notification_large.png"));
  }
}

@media (max-width: 1279px) and (min-width: 992px) {
  .corp {
    //background-image: url(base-url("../images/bg/bg_notification_petit.png"));
  }
}

@media (max-width: 600px) {
  .corp {
    //background-image: url(base-url("../images/bg/bg_notification_petit.png"));
  }
}
