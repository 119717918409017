.hero {
  /*
    @include full-height;
    max-width: 1920px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -100px;

    @include media(medium){
        align-items: flex-end;
        padding-bottom: 11vh;
    }
    @include media(extra-small){
        padding-bottom: 6vh;
        margin-top: -80px;
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        right: 0;

        width: 45%;
        height: 100%;

        background-image: url(base-url('../images/bg/hero-bg.png'));
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
        
        
        margin-top: 4.5rem;

        @include media(extra-large){
            margin-top: 0;
        }
        @include media(large){
            width: 40%;
            background-position: center right;
        }
        @include media(medium){
            background-image: url(base-url('../images/bg/hero-bg-mobile.png'));
            top: 1%;
            right: 5%;
            width: 100%;
            height: 53vh;
        }
        @include media(extra-small){
            top: 1%;
            right: 0%;
            height: 55vh;
        }
        @include media(mobile){
            height: 42vh;
        }
    }
    */
  &__title {
    color: $colour-secondary;
    @include h1-rfs;
  }
  &__description {
    max-width: 40rem;

    @include media(large) {
      max-width: 32rem;
    }

    @include h6-rfs;
  }
  &__cta {
    font-size: 1rem;
    padding: 0.8rem 5rem;
    border-bottom-left-radius: 0;

    @include media(extra-small) {
      width: 100%;
      border-bottom-left-radius: 2rem;
    }
  }
}

.landing {
  &-process {
    margin-top: 10rem;

    @include media(extra-small) {
      margin-top: 4rem;
    }

    &__logo {
      text-align: center;

      & img,
      svg {
        width: 54vmin;

        @include media(extra-small) {
          width: 15rem;
        }
      }
    }
    &__number {
      color: white;
      padding: 0.4rem 1.2rem;
      background: $colour-primary;
      border-radius: 50%;

      @include media(small) {
        padding: 0.3rem 1rem;
      }

      @include h3-rfs;
    }
    &__item {
      margin-top: 5rem;

      @include media(medium) {
        margin-top: 5rem;
      }
      @include media(small) {
        margin-top: 3rem;
      }
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include media(medium) {
        justify-content: center;
      }
    }
    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include media(medium) {
        justify-content: center;
      }
    }
    &__img {
      max-width: 90%;
      filter: drop-shadow(0 32px 32px rgba(#626262, 0.14));

      @include media-min-width(ultra-large) {
        max-width: 100%;
      }
      @include media(medium) {
        max-width: 100%;
      }

      &--partners {
        @include media(small) {
          max-width: 112%;
        }
      }
    }
  }

  &-native {
    margin-top: 3rem;
    padding-top: 4rem;
    padding-bottom: 3rem;
    /*background: #F7F7F7;*/

    &__description {
      line-height: 1.5;
    }
    &__btn {
      @include media(mobile) {
        width: 100%;
      }
    }
  }

  &-formation {
    margin-top: 3rem;
  }
  &-invite {
    margin-top: 5rem;

    &__main {
      background: #fbd4ab;
      min-height: 20rem;
      border-radius: 2rem;
      border-bottom-left-radius: 0;
      overflow: hidden;
    }
    &__bg {
      background: url(base-url("../images/content/landing_cta_bg.jpg"));
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center left;
      background-color: #fbd4ab;

      @include media(medium) {
        display: none;
      }
    }
    &__content {
      padding: 4rem 4rem 4rem 0rem;

      @include media(medium) {
        padding: 3rem;
      }
      @include media(extra-small) {
        padding: 2rem;
      }
    }
    &__text {
      margin-top: 0.75rem;
    }
  }

  &-uwandzani {
    margin-top: 5rem;
    background: $colour-secondary;

    &__title {
      white-space: nowrap;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @include media(medium) {
        align-items: center;
      }
    }
    &__text {
      color: white;
    }
    &__img {
      max-width: 75%;
      float: right;

      @include media(large) {
        max-width: 100%;
      }
      @include media(medium) {
        float: none;
        max-width: 30vw;
      }
    }
  }
  &-testimonials {
    margin-top: 5rem;

    &__item {
      margin-top: 3rem;
    }
    &__infos {
      display: flex;
    }
    &__img {
      width: 30%;
      border-radius: 50%;
      max-width: 6rem;
      min-width: 5rem;
    }
    &__name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;

      & .user-card__name {
        margin-top: 0;
      }
    }

    &__description {
      margin-top: 1rem;
      @include p-rfs;
    }
  }
}
