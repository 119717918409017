.custom-btn{
    $self: &;
    font-weight: 700;
    background: $colour-primary;
    padding: .6rem 3rem !important;
    outline: none;
    border: none;
    color: white;
    border-radius: 2rem;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
	/*width: 232px !important;*/
	font-size: 18px;
    /*margin-left: -22px !important;*/
    
    @include media(large){
        padding: .6rem 2rem;
    }

    &--2{
        background-color: transparent;
        color: #FF6600 ;
        border-color: #FF6600 ;
        $self: &;
        font-weight: bold;
        /*padding: .6rem 3rem !important;*/
        outline: none;
        border-radius: 2rem;
        transition: color .2s ease-in-out, background-color .2s ease-in-out;
	    width: 144px;
    }

    &:hover{
        background-color: desaturate(darken($colour-primary, 10%), 10%);
    }

    &--outline{
        color: $colour-link;
        background: transparent;
        border: 1px solid $colour-link;
		

        &:hover{
            color: darken($colour-link, 20%);
            border-color: darken($colour-link, 20%);
            background-color: transparent;
        }

        &--secondary{
            color: $colour-primary;
            border-color: $colour-primary;

            &:hover{
                color: desaturate(darken($colour-primary, 10%), 10%);;
                border-color: desaturate(darken($colour-primary, 10%), 10%);;
            }
        }
    }
    &--white{
        background-color: white;
        color: $colour-primary;
        border: none;

        &:hover{
            color: desaturate(darken($colour-primary, 10%), 10%);
            background-color: white;
        }
    }

    &--valid{
        
        background-color: #28a745;

        &:hover{
        background-color: #28a745;;
    }
    }
}