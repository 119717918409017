
@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?4se7ln');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?4se7ln#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?4se7ln') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?4se7ln') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?4se7ln##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-accomplishment {
  &:before {
    content: $icon-accomplishment; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-community {
  &:before {
    content: $icon-community; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}

.icon-profil {
  &:before {
    content: $icon-profilDesactive; 
  }
}

.icon-actualite {
  &:before {
    content: $icon-actualiteDesactive; 
  }
}

.icon-conversation {
  &:before {
    content: $icon-conversationDesactive; 
  }
}

.icon-exercice {
  &:before {
    content: $icon-exerciceDesactive; 
  }
}

.icon-gratitude {
  &:before {
    content: $icon-gratitudeDesactive; 
  }
}

.icon-language {
  &:before {
    content: $icon-languageDesactive; 
  }
}

.icon-story {
  &:before {
    content: $icon-storyDesactive; 
  }
}
/*
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
*/
.icon-messages {
  &:before {
    content: $icon-messages; 
  }
}

.icon-cloche {
  &:before {
    content: $icon-cloche; 
  }
}
.icon-formation {
  &:before {
    content: $icon-formation; 
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}

