.custom-dropdown-menu {
    min-width: 13rem;

    &::before, &::after{
        content: "";
        display: inline-block;
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);

        position: absolute;
        
    }    

    &--dropdown{
        &::before{
            border-bottom: 9px solid rgba(0, 0, 0, 0.2);
            top: -8px;
            left: 5%; /* position */
    
        }
        &::after{
            border-bottom: 8px solid #FFFFFF;
            top: -7px;
            left: 5%; /* position */
        }
    }
    &--dropup{
        &::before{
            border-top: 9px solid rgba(0, 0, 0, 0.2);
            bottom: -8px;
            right: 50%;
        }
        &::after{
            border-top: 8px solid #FFFFFF;
            bottom: -7px;
            right: 50%;
        }
    }
    &--responsive{
        @include media(medium){
            min-width: 10rem;

            &::before, &::after{
                right: 75%;
            }
        }
    }
}