.login-page{
    &__logo{
        width: 5rem;

        @include media(extra-small){
            width: 3rem;
        }
    }
    &__signup{
        position: fixed;
        top: 2rem;
        right: 2rem;
    }
    &__login{
        font-weight: bold;
        color: $colour-link;
        @include small-text-rfs;
    }
    &__forgot{
        color: $colour-primary;
        font-weight: bold;

        &:hover{
            text-decoration: none;
            color: $colour-third;
        }
    }
}