.chat{
    $self: &;
    height: 100%;   
    width: 100%;

    &__container{
        height: 100%;
        width: 100%;
    }
    &__wrapper{
        background-color: white;
        height: 100%;
        width: 100%;
        border-radius: $form-radius * 2;
        overflow: hidden;
        position: relative;
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media(large){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        @include media(medium){
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include media(small){
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
    &__messaging{
        width: 100%;
        border-bottom: 1px solid $form-border-color;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__previous{
        width: 8%;
        min-width: 2rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.7rem;
        cursor: pointer;
        color: $colour-primary;
    }
    &__notification{
        width: 4%;
        min-width: 2rem;

        & span{
            display: none;
            width: 1.4rem;
            height: 1.4rem;
            text-align: center;
            border-radius: 50%;
            font-weight: bold;
            color: white;
            background-color: $colour-primary;
            @include small-text-rfs;
        }

        &--new{
            & span{
                display: block;
            }
        }
    }
    &__infos{
        flex-grow: 1;
    }
    &__menu{
        border-radius: 50%;
        font-size: 5px;
        background-color: rgba($colour-primary, 0.2);
        width: 2rem;
        height: 2rem;
        color: $colour-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &__content{
        flex-grow: 1;
        overflow-y: auto;
        scrollbar-color: $scroll-bar;

        @include media(small){
            scrollbar-color: transparent transparent;
        }
    }
    &__list-messages{
        list-style: none;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
        & li {
            margin-bottom: 0.7rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            & #{$self}__bubble{
                margin-bottom: 0.2rem;
            }
        }
    }
    &__bubble{
        position: relative;
        color: darken($colour-text, 20%);
        padding: 0.5rem 1.5rem;
        border-radius: 22px;
        background-color: $bg-page-logged;
        max-width: 30rem;

        @include chat-rfs;

        @include media(small){
            padding: 0.5rem 1rem;
        }
        @include media(extra-small){
            max-width: 16rem;
        }

        overflow: hidden;
        overflow-wrap: break-word;
        word-break: break-word;

        &--you{
            color: white;
            background-color: rgba($colour-primary, 1);
        }
        &--me{
            background-color: $bg-page-logged;
            align-self: flex-end;
        }
    }
    &-member{
        &__wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &__avatar{
            position: relative;
            width: 4.5rem;
    
            & img {
                border-radius: 50%;
                width: 100%;
            }

            @include media(small){
                width: 3.5rem;
            }
        }
        &__name{
            font-weight: bold;
            color: $colour-title;
            margin-top: auto;
            @include h4-rfs;
        }
        &__details{
            margin-left: 1.25rem;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @include media(small){
                margin-left: 1rem;
            }
        }
        &__age{
            @include small-text-rfs;
            color: $colour-link;
            position: relative;

            &::after{
                content: " . ";
                font-size: 0px;
                position: absolute;
                top: 50%;
                right: -4px;
                width: 3px;
                height: 3px;
                background-color: $colour-link;
                border-radius: 50%;
            }
        }
        &__status{
            color: $colour-link;
            @include very-small-text-rfs;
        }
    }
    &__send-container{
        padding-top: 1rem;
    }
    &__profile{
        width: 100%;
        height: 100%;
        max-width: 20rem;
    }

    &--mobile{
        position: fixed;
        top: 0;
        left: 0;
        z-index: $navbar-z-index + 1;
        transform: translateY(100%);
        display: none;
        transition: transform .3s ease-in-out .1s;

        & #{$self}__wrapper{
            border-radius: 0;
        }
        &#{$self}--show{
            display: block;
            transform: translateY(0%);
            border-radius: 0;
        }
    }
}