.custom-taginput {
    background-color: rgba($colour-fourth, 0.2);
    padding: 0.3rem 0.3rem 0.3rem 0.9rem;
    margin-right: 2px;
    border-radius: 1rem;
    display: inline-block;
    
    @include small-text-rfs;
    color: $colour-fourth;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;

    & [data-role="remove"]:after{
        content: "";
        padding: 0.175rem 0.7rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5LjIgOS4yIj4KICA8Zz4KICAgIDxsaW5lIHgxPSIxIiB5MT0iMSIgeDI9IjguMiIgeTI9IjguMiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPGxpbmUgeDE9IjguMiIgeTE9IjEiIHgyPSIxIiB5Mj0iOC4yIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");
        background-repeat: no-repeat;
        background-size: 0.65rem;
        background-position: center;
        background-color: $colour-fourth;
        border-radius: 50%;
    }
}
  