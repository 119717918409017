.messages-page{
    height: 100vh;

    &__wrapper{
        position: relative;
        width: 100%;
        height: 100%;
    }
    &__list{
        list-style: none;
    }
    &__list-scroll{
        height: 100%;
        overflow-y: auto;
        scrollbar-color: $scroll-bar;
    }
}

.app-message {

    //font page message
    background-color: $bg-page-logged;/*$colour-font-message ;*/

    //input de recherche
    .custom-form__search-wrapper #search {
        background-color: $colour-search-wrapper;
    }

    //header discusstion
    .chat-member__wrapper {
        background-color: $colour-search-wrapper;
    }

    //conversation
    .chat__wrapper {
        background-color: $colour-search-wrapper;
    }

    //section profil
    .user-profile {
        background-color: $bg-page-logged /*#FFFDFA /*$colour-link*/;
    }

    //list conversation
    .message-page__wrapper {
        background-color: $bg-page-logged /*#FFFDFA /*$colour-link*/;
    }
}

#exercice {

    label, legend , center{
        color : $colour-secondary
    }

    .span {
        color: $colour-third 
    }

    .list-group-item-exercice, .mask, .line_ {
        background-color: $bg-page-logged-item ;
        color : $colour-secondary;
     /*  
        margin: 15px 5px;
        border: none;
        box-shadow: transparent 0 0 0;
        border: solid 1px orange;
        border-radius: 10px !important;
        overflow: hidden;
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        font-family: Ubuntu;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
       */ 
    }


}

#modal-xl-1-annonce___BV_modal_content_ , #modal-xl-1___BV_modal_content_ {
    background-color: $bg-page-logged;
}