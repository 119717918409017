.uwandzani-logo{
    width: 5rem;

    @include media(extra-small){
        width: 4.5rem;
    }

    &--large{
        width: 6rem;

        @include media(extra-small){
            width: 4.5rem;
        }
    }
    &--logged{
        @include media(medium){
            width: 3.5rem;
        }
    }
}