.section{
    
    &__title{
        color: $colour-title;
        line-height: 1.2;

        @include colour-modifiers;

        &--center{
            text-align: center;
        }
        &--nowrap{
            white-space: nowrap;
        }
    }
}