.tag{
    background-color: rgba($colour-primary, 0.2); 
    color: $colour-primary;         
    margin-right: 0.4rem;
    border-radius: 1rem;
    @include tag;

    &--small{
        @include tag-small;
    }
    &--third{
        background-color: rgba($colour-fourth, 0.2);    
        color: $colour-fourth;
    }
}