// details_event_styles
.row {
    padding: 0px 25px !important;
  }
  .row {
    .event_name {
      display: flex;
      flex-direction: column;
      .event_title {
        color: #363c4f;
        //   text-transform: uppercase;
        font-weight: bold;
        font-size: 40px;
        font-family: "faune text";
        margin-bottom: 10px;
      }
  
      .details_publication_author {
        font-size: 15px;
        display: flex;
        margin-bottom: 25px;
  
        .publication_author {
          font-weight: bold;
        }
  
        .type_event {
          margin-left: 15px;
        }
      }
    }
  
    .example-wrapper {
      display: flex;
  
      .details_event {
        // flex: 1;
        width: 60%;
  
        .image_event {
          width: 100%;
  
          .img_event {
            width: 100%;
          }
        }
  
        .event_resume_title,
        .event_organiser_title,
        .event_participants_title {
          font-size: 22px;
          font-weight: bold;
          margin-top: 25px;
        }
        .event_resume_details {
          font-family: "faune text";
          font-size: 18px;
        }
  
        .event_organiser,
        .event_participants {
          //   width: 100%;
  
          ._event_organiser_header,
          ._event_participants_header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn_partner {
              border: none;
              font-size: 18px;
              line-height: 24px;
              background: transparent;
              // width: 25%;
              padding: 10px;
              display: flex;
              align-items: center;
              color: #ff6600;
              ._fa-user-plus {
                margin-left: 05px;
              }
              &:hover {
                border-radius: 100px;
                background: #ff6600;
                color: #fff;
              }
  
              transition: all 100ms ease-in;
            }
          }
  
          .event_organiser_list,
          .event_participants_list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
  
          .event_organiser_users,
          .event_participants_users {
            display: flex;
            flex-direction: column;
            background: #fff;
            width: 150px;
            height: 200px;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            align-items: center;
            font-family: "faune text";
            cursor: pointer;
  
            .event_organiser_pictures,
            .event_participants_picture {
              width: 90px;
              height: 90px;
              border-radius: 250px;
              object-fit: cover;
              margin-top: 21px;
              margin-bottom: 12px;
            }
  
            .event_organiser_name,
            .event_participants_name {
              font-weight: bold;
              font-size: 20px;
            }
  
            .event_organiser_culture,
            .event_participants_culture {
              font-size: 16px;
            }
  
            ._fa-plus {
              width: 90px;
              height: 90px;
              margin-top: 21px;
              margin-bottom: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #ff6600;
              border-radius: 250px;
              color: #fff;
            }
          }
        }
      }
  
      .details_date_event {
        // flex: 1;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: end;
        padding-left: 15px;
  
        .event_date {
          background: #d9d9d9;
          width: 100%;
          height: 120px;
          // position: sticky;
          // top: 24px;
          padding: 0 20px;
          // bottom: 15px;
          margin-left: 25px;
          // margin: 0 25px;
          overflow: hidden;
          background: #fffdfa;
          border-radius: 5px;
          .details_date_wrapper {
            position: relative;
            width: 100%;
            height: 100%;
  
            .img_event {
              width: 35%;
              position: absolute;
              top: -10px;
              bottom: 0;
              right: -50px;
            }
  
            .details_date {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              span.calendar_icon {
                width: 87px;
                height: 87px;
                background: #ddaf7c;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
  
                // &::after{
                //   content: "";
                //   border: 1px solid #818282;
                //   height: 80%;
                //   position: absolute;
                //   left: 85px;
                //   width: 0px;
                // }
              }
  
              .date_and_place {
                display: flex;
                flex-direction: column;
                // justify-content: center;
                margin-left: 20px;
                width: 60%;
  
                .date {
                  display: flex;
                  justify-content: start;
                  align-items: start;
  
                  span.clock_icon {
                    margin: 2px 15px 0 0;
                  }
                  .day {
                    span {
                      width: 25px;
                      font-family: "faune text";
                      font-size: 18px;
                      // font-weight: bold;
                      // color: #363C4F;
                    }
                  }
                }
  
                .place {
                  display: flex;
                  justify-content: start;
                  align-items: start;
                  // width: 150px;
                  span.location_icon {
                    margin: 2px 15px 0 0;
                  }
                  .location {
                    span {
                      width: 25px;
                      font-family: "faune text";
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
  
        .preselections {
          background: #fffdfa;
          padding: 15px 20px;
          margin-top: 15px;
          border-radius: 10px;
          width: 100%;
          // position: sticky;
          // top: 150px;
          .preselection_header {
            display: flex;
            align-items: center;
            .heart-circle-boldt_icon {
              margin-right: 15px;
              width: 50px;
              height: 50px;
              border-radius: 100px;
              background: rgba(240, 101, 26, 0.1);
              display: flex;
              justify-content: center;
              align-items: center;
              color: #f0651a;
            }
  
            .title {
              font-family: "faune text";
              font-size: 22px;
              font-weight: bold;
            }
          }
          .preselections_cards {
            width: 100%;
            margin-top: 30px;
            .cards_partenairs,
            .cards_participants {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: #fffdfa;
              padding: 10px 20px;
              margin-top: 15px;
              border-radius: 5px;
              border: 1px solid #ff6600;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              .identity_partenairs,
              .identity_participants {
                display: flex;
                align-items: center;
                // justify-content: space-between;
                width: 100%;
  
                img {
                  width: 55px;
                  height: 55px;
                  border-radius: 50px;
                  margin-right: 15px;
                }
  
                .name_and_culture {
                  display: flex;
                  flex-direction: column;
  
                  .name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #696969;
                  }
  
                  .culture {
                    color: #ff6600;
                    font-size: 13px;
                  }
                }
              }
  
              .message {
                a {
                  color: #ff6600;
                }
              }
            }
          }
  
          .all_preselections_buttons {
            a {
              display: inline-block;
              font-size: 15px;
              font-weight: bold;
              margin-top: 25px;
              color: #696969;
              .arrow-right-long_icon {
                margin-left: 10px;
                color: #f0651a;
              }
            }
          }
        }

        .btn_info_speed_dating{
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  
    // .view_more{
    //   background: #f0651a;
    //   width: 60px;
    //   height: 60px;
    //   border-radius: 60px 0 0 60px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   color: #fff;
    //   font-size: 2rem;
    //   position: fixed;
    //   top: 55vh;
    //   right: 0px;
    // }
  }
  .info_payement {
    background: #fffdfa;
    width: 100%;
    border-radius: 30px 30px 0 0;
    padding: 15px 0;
    position: sticky;
    bottom: 0;
    margin-top: 50px;
    height: 100px;
    display: flex;
    align-items: center;
  
    .payement_bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0 #0000;
  
      .info_and_price {
        display: flex;
        flex-direction: column;
  
        .event_date {
          font-family: "faune text";
        }
  
        .event_price {
          font-family: "Ubuntu";
          font-size: 25px;
          color: #ff6600;
          font-weight: bold;
        }
      }
  
      .btn_group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 35%;
  
        a.share_btn {
          color: #ff6600;
          border: 1px solid #ff6600;
          padding: 10px 40px;
          font-size: 18px;
          border-radius: 50px;
          transition: all 100ms ease-in;
  
          &:hover {
            background-color: #ff6600;
            color: #fff;
          }
        }
  
        a.participate_btn {
          background-color: #ff6600;
          padding: 10px 60px;
          font-size: 18px;
          border-radius: 50px;
          color: #fff;
        }
      }
    }
  }
  
  // end details_event_styles
  
  // list_partenairs_style, list_participants_style
  .example-wrapper {
    width: 100%;
    .partenairs_list,
    .participants_list {
      width: 60%;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header_title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          div {
            .arrow_left_icon {
              margin-right: 10px;
            }
            .title {
              font-size: 25px;
              font-family: "faune text";
              font-weight: bold;
            }
          }
          .event_organisers_name {
            margin-left: 36px;
            font-size: 18px;
          }
        }
        .btn_partner {
          border: none;
          font-size: 18px;
          line-height: 24px;
          background: transparent;
          // width: 25%;
          padding: 10px;
          display: flex;
          align-items: center;
          color: #ff6600;
          ._fa-user-plus {
            margin-left: 05px;
          }
          &:hover {
            border-radius: 100px;
            background: #ff6600;
            color: #fff;
          }
  
          transition: all 100ms ease-in;
        }
      }
      .list_partenairs_cards,
      .list_participants_cards {
        width: 100%;
        margin-top: 30px;
        .cards_partenairs,
        .cards_participants {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fffdfa;
          padding: 10px 20px;
          margin-top: 15px;
          border-radius: 5px;
          border: 1px solid #ff6600;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          .identity_partenairs,
          .identity_participants {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            width: 100%;
  
            img {
              width: 65px;
              height: 65px;
              border-radius: 50px;
              margin-right: 25px;
            }
  
            .name_and_culture {
              display: flex;
              flex-direction: column;
  
              .name {
                font-size: 20px;
                font-weight: bold;
                color: #696969;
              }
  
              .culture {
                color: #ff6600;
                font-size: 15px;
              }
            }
          }
  
          .message {
            a {
              color: #ff6600;
            }
          }
        }
      }
    }
  
    .date_event {
      width: 40%;
      display: flex;
      justify-content: end;
  
      .event_date {
        background: #fff;
        width: 100%;
        height: 120px;
        position: sticky;
        top: 142px;
        padding: 0 20px;
  
        bottom: 15px;
        margin-left: 25px;
        // margin: 0 25px;
        overflow: hidden;
        background: #fffdfa;
        border-radius: 5px;
        .details_date_wrapper {
          position: relative;
          width: 100%;
          height: 100%;
  
          .img_event {
            width: 35%;
            position: absolute;
            top: -10px;
            bottom: 0;
            right: -50px;
          }
  
          .details_date {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            span.calendar_icon {
              width: 87px;
              height: 87px;
              background: #ddaf7c;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
  
              // &::after{
              //   content: "";
              //   border: 1px solid #818282;
              //   height: 80%;
              //   position: absolute;
              //   left: 85px;
              //   width: 0px;
              // }
            }
  
            .date_and_place {
              display: flex;
              flex-direction: column;
              // justify-content: center;
              margin-left: 20px;
              width: 60%;
  
              .date {
                display: flex;
                justify-content: start;
                align-items: start;
  
                span.clock_icon {
                  margin: 2px 15px 0 0;
                }
                .day {
                  span {
                    width: 25px;
                    font-family: "faune text";
                    font-size: 18px;
                    // font-weight: bold;
                    // color: #363C4F;
                  }
                }
              }
  
              .place {
                display: flex;
                justify-content: start;
                align-items: start;
                // width: 150px;
                span.location_icon {
                  margin: 2px 15px 0 0;
                }
                .location {
                  span {
                    width: 25px;
                    font-family: "faune text";
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  // end list_partenairs_style, list_participants_style
  
  // profil_participants_style
  .example-wrapper {
    .profil {
      width: 60%;
      .profil_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header_title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          div {
            .arrow_left_icon {
              margin-right: 10px;
            }
            .title {
              font-size: 25px;
              font-family: "faune text";
              font-weight: bold;
            }
          }
          .event_organisers_name {
            margin-left: 36px;
            font-size: 18px;
          }
        }
      }
  
      .content_profil_participants {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .profil_information {
          display: flex;
          flex-direction: column;
          align-items: center;
          .profil_picture {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            img {
              width: 200px;
              height: 200px;
              border-radius: 50%;
              object-fit: cover;
              // border-radius: 2350px;
              // vertical-align: middle;
              // clip-path: circle(30%);
            }
          }
          .profil_name {
            font-size: 30px;
            font-weight: bold;
            margin-top: 20px;
          }
  
          .profil_culture {
            font-size: 20px;
            color: #f0651a;
            font-weight: 400;
          }
  
          .profil_action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 15%;
            max-width: 100%;
            margin-top: 5px;
            padding: 10px;
  
            .message_icon,
            .heart_pulse_icon {
              width: 60px;
              height: 60px;
              border-radius: 100px;
              background: rgba($color: #f0651a, $alpha: 0.1);
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 15px;
              color: #f0651a;
            }
          }
        }
  
        .biography,
        .culture {
          width: 75%;
          .biography_title,
          .culture_title {
            font-size: 22px;
            font-weight: bold;
            margin-top: 25px;
            .biography_content,
            .culture_content {
              font-family: "faune text";
              font-size: 18px;
            }
          }
        }
  
        .culture {
          .culture_content {
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            // justify-content: space-around;
            a {
              margin-top: 10px;
              .language_wrapper {
                border: 1px solid #f0651a;
                padding: 5px 15px;
                margin: 5px 10px;
                border-radius: 25px;
                color: #f0651a;
                font-family: "faune text";
                font-weight: bold;
              }
            }
          }
        }
  
        .principal_profile {
          margin-top: 25px;
          a.btn_profile {
            background: #f0651a;
            color: #fff;
            padding: 10px 60px;
            font-size: 18px;
            width: 100%;
            border-radius: 50px;
          }
        }
      }
    }
  }

  .close_icon{
    margin-top: 5px;
  }
  
  // end profil_participants_style
  
  // responsive style
  //responsive_detail_event_style
  @media screen and (max-width: 1200px) {
    .py-5 {
      padding: 0px !important;
    }
    .row {
      padding: 0px !important;
      .event_name{
        display: none;
      }
       .more_btn{
        background: #f0651a;
        width: 50px;
        height: 50px;
        border-radius: 50px 0 0 50px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 65vh;
        right: 0;
        z-index: 1000;
       }
      .example-wrapper {
        position: relative;
        .details_event {
          width: 100%;
          .image_event {
            width: 100%;
            position: relative;
            .img_event {
              width: 100%;
              border-radius: 0 0 35px 35px;
            }
            .type_event_for_mobile {
              font-size: 18px;
              color: #fff;
              position: absolute;
              bottom: 15px;
              padding: 0 20px;
              font-weight: bold;
            }
          }
  
          .details_date_event_sm {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            margin-top: 20px;
  
            .event_date {
              background: #d9d9d9;
              width: 100%;
              height: 100px;
              padding: 0 15px;
              overflow: hidden;
              background: #fffdfa;
              border-radius: 5px;
              .details_date_wrapper {
                position: relative;
                width: 100%;
                height: 100%;
  
                .img_event {
                  width: 35%;
                  position: absolute;
                  top: -10px;
                  bottom: 0;
                  right: -50px;
                }
  
                .details_date {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  span.calendar_icon {
                    width: 60px;
                    height: 60px;
                    background: #ddaf7c;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
  
                    // &::after{
                    //   content: "";
                    //   border: 1px solid #818282;
                    //   height: 80%;
                    //   position: absolute;
                    //   left: 85px;
                    //   width: 0px;
                    // }
                  }
  
                  .date_and_place {
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    width: 60%;
                    padding-left: 15px;
  
                    .date {
                      display: flex;
                      justify-content: start;
                      align-items: start;
  
                      span.clock_icon {
                        margin: 2px 10px 0 0;
                        font-size: 16px;
                      }
                      .day {
                        line-height: 25px;
                        span {
                          width: 25px;
                          font-family: "faune text";
                          font-size: 15px;
                          // font-weight: bold;
                          // color: #363C4F;
                        }
                      }
                    }
  
                    .place {
                      display: flex;
                      justify-content: start;
                      align-items: start;
                      // width: 150px;
                      span.location_icon {
                        margin: 2px 10px 0 0;
                        font-size: 16px;
                      }
                      .location {
                        line-height: 25px;
                        span {
                          width: 25px;
                          font-family: "faune text";
                          font-size: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
  
          .event_name_sm {
            display: flex;
            flex-direction: column;
            .event_title {
              color: #363c4f;
              //   text-transform: uppercase;
              font-weight: bold;
              font-size: 22px;
              font-family: "faune text";
              margin-bottom: 10px;
              padding: 0 20px;
            }
  
            .details_publication_author {
              padding: 0 20px;
              font-size: 15px;
            }
          }
          .event_resume,
          .event_organiser,
          .event_participants {
            padding: 0 20px;
          }
  
          
  
          .event_resume {
            margin-top: 15px;
          }
  
          .event_organiser {
            ._event_organiser_header {
              // .event_organiser_title {
              //   // font-size: 18px;
              // }
              .btn_partner {
                width: 50%;
              }
            }
  
            .event_organiser_list {
              .event_organiser_users {
                margin-top: 15px;
              }
            }
          }
  
          .event_participants {
            // ._event_participants_header {
            //   .event_participants_title {
            //     font-size: 18px;
            //   }
            // }
  
            .event_participants_list {
              .event_organiser_users {
                margin-top: 15px;
              }
            }
          }
        }
  
        .details_date_event {
          z-index: 1001;
          position: absolute;
          top: 0;
          bottom: 0;
          background: #fff;
          width: 100%;
          right: 200%;

         .sup_info{
          right: 0;
         }
         .event_date{
          display: none;
         }
        }


        .slide_details_in {
          right: 0;
          animation-duration: 0.5s;
          animation-name: slidein;
        }
      
        @keyframes slidein {
          from {
            right: 100%;
          }
      
          to {
            right: 0%;
          }
        }
        .profil {
          width: 100%;
          padding: 0 20px;
          margin-top: 35px;
          .profil_header {
            .header_title {
              div {
                .title {
                  font-size: 20px;
                }
              }
              .event_organisers_name {
                display: none;
              }
            }
          }
  
          .content_profil_participants {
            .profil_information {
              .profil_picture {
                margin-top: 30px;
                img {
                  width: 150px;
                  height: 150px;
                }
              }
              .profil_name {
                margin-top: 20px;
                font-size: 25px;
              }
  
              .profil_culture {
                font-size: 16px;
              }
            }
            .biography,
            .culture {
              width: 100%;
            }
          }
        }
      }
  
      .date_event {
        display: none;
      }
  
      .participants_list,
      .partenairs_list {
        width: 100%;
        margin-top: 35px;
        .header {
          padding: 0 20px;
  
          .header_title {
            div {
              .title {
                font-size: 20px;
                // background: red;
              }
            }
  
            .event_organisers_name {
              display: none;
            }
          }
  
          .btn_partner {
            width: 40%;
            font-size: 16px;
          }
        }
        .list_partenairs_cards,
        .list_participants_cards {
          padding: 0 20px;
          // .cards_partenairs{
  
          // }²
        }
      }
    }
    .info_payement {
      .container {
        .row {
          .payement_bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .info_and_price {
              width: 50%;
              .event_date {
                font-size: 15px;
              }
              .event_price {
                font-size: 18px;
              }
            }
  
            .btn_group {
              .share_btn {
                display: none;
              }
              .participate_btn {
                font-size: 15px;
                width: 100%;
                padding: 15px 18px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  
    .wrapper {
      .title {
        font-size: 50px;
        text-align: center;
      }
      .categories_card {
        justify-content: center;
      }
  
      .search {
        width: 90%;
      }
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 390px) {
    .info_payement {
      .container {
        .row {
          .payement_bar {
            .info_and_price {
              .event_date {
                font-size: 12px;
              }
              .event_price {
                font-size: 15px;
              }
            }
  
            .btn_group {
              .participate_btn {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  
    .row {
      .example-wrapper {
        .details_event {
          .details_date_event_sm {
            .event_date {
              .details_date_wrapper {
                .img_event {
                  width: 45%;
                  top: -1px;
                  right: -45px;
                }
                .details_date {
                  span.calendar_icon {
                    width: 50px;
                    height: 50px;
                  }
                  .date_and_place {
                    .date {
                      span.clock_icon {
                        font-size: 13px;
                      }
                      .day {
                        line-height: 15px;
                        span {
                          font-size: 12px;
                        }
                      }
                    }
  
                    .place {
                      span.location_icon {
                        font-size: 13px;
                      }
                      .location {
                        line-height: 15px;
                        span {
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .wrapper {
      .categories_card {
        justify-content: center;
      }
    }
  }
  
  .content_event {
    display: flex;
    justify-content: space-between;
    // width: 100%;
    height: 100%;
    .event_cards {
      .event_name {
        .event_title {
          font-size: 25px;
        }
        .details_publication_author {
          .type_event {
            margin-left: 0;
          }
        }
      }
    }
  }
  
  .event_cards {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    padding-bottom: 100px;
    // background: #fff;
    // justify-content: center;
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none; /* width of the entire scrollbar */
    }
  }
  
  .input_search {
    width: 100%;
    border-radius: 13px;
  }
  
  .list_event_date_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }
  
  .card_date {
    width: 100%;
    background: #fff;
    border: 1px solid #f0651a;
    border-radius: 10px;
    padding: 10px;
    margin-top: 15px;
  
    .card_information {
      display: flex;
      // justify-content: space-between;
      align-items: center;
  
      .card_img {
        img {
          width: 90px;
          height: 80px;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .date_place_participants {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
  
        .date {
          font-family: "faune text";
          font-size: 14px;
          font-weight: bold;
        }
        .place {
          display: flex;
          align-items: flex-start;
          // margin-top: 10px;
          .location_icon {
            margin-right: 10px;
            font-size: 0.9rem;
          }
          .location {
            font-family: "faune text";
            font-size: 12px;
          }
        }
  
        .participants {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // margin-top: 10px;
          .participants_number {
            display: flex;
            align-items: flex-start;
            .users_icons {
              margin-right: 10px;
              font-size: 0.9rem;
            }
            .numbers {
              font-family: "faune text";
              font-size: 12px;
            }
          }
  
          .participants_status {
            .status {
              .incomplete {
                display: none;
              }
              .complete {
                font-family: "faune text";
                font-size: 8px;
                padding: 5px 14px;
                background: #419d78;
                border-radius: 15px;
                color: #fff;
                // display: none;
              }
            }
          }
        }
      }
    }
  }
  
  .event_information_view {
    flex: 2 1 0;
    background: #fff;
    padding: 30px 20px 100px;
    border-radius: 15px;
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none; /* width of the entire scrollbar */
    }
  
    .event_name {
      display: flex;
      .details_publication_author {
        flex-direction: column;
      }
      .event_title {
        font-size: 30px;
        color: #f0651a;
      }
    }
    .event_participants,
    .event_organiser {
      //   width: 100%;
  
      ._event_organiser_header,
      ._event_participants_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .event_participants_title,
        .event_organiser_title {
          font-family: "faune text";
          font-size: 20px;
          font-weight: bold;
          color: #f0651a;
        }
      }
  
      .event_organiser_list,
      .event_participants_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
  
      .event_organiser_users,
      .event_participants_users {
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 110px;
        height: 150px;
        border: 1px solid #f0651a;
        border-radius: 10px;
        align-items: center;
        font-family: "faune text";
        cursor: pointer;
        .event_organiser_number_rest {
          font-size: 14px;
        }
  
        .event_organiser_pictures,
        .event_participants_picture {
          width: 60px;
          height: 60px;
          border-radius: 250px;
          object-fit: cover;
          margin-top: 19px;
          margin-bottom: 12px;
        }
  
        .event_organiser_name,
        .event_participants_name {
          font-weight: bold;
          font-size: 18px;
          color: #f0651a;
        }
  
        .event_organiser_culture,
        .event_participants_culture {
          font-size: 12px;
        }
  
        ._fa-plus {
          width: 60px;
          height: 60px;
          margin-top: 19px;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ff6600;
          border-radius: 250px;
          color: #fff;
        }
      }
    }
  }
  
  .details_event {
    .title {
      font-size: 20px;
      font-family: "faune text";
      font-weight: bold;
      color: #f0651a;
    }
    .content {
      font-family: "faune text";
      font-size: 18px;
    }
    hr {
      display: inline-block;
      margin: auto;
      text-align: center;
    }
  
    .date_and_place {
      .date {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .clock_icon {
          margin-right: 15px;
          display: flex;
          color: #f0651a;
        }
        .day {
          font-size: 18px;
          font-family: "faune text";
        }
      }
  
      .place {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .location_icon {
          margin-right: 15px;
          display: flex;
          color: #f0651a;
        }
  
        .location {
          font-size: 18px;
          font-family: "faune text";
        }
      }
  
      .participants {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .users_icons {
          margin-right: 15px;
          display: flex;
          color: #f0651a;
        }
        .participants_numbers {
          font-size: 18px;
          font-family: "faune text";
        }
      }
  
      .status {
        font-family: "faune text";
        font-size: 18px;
        font-weight: bold;
        margin-top: 15px;
        .complete_status {
          color: #419d78;
        }
      }
    }
  }
  
  .sup_info {
    flex: 1 1 0;
    padding-left: 15px;
    
  }
  
  .preselections,
    .calendar_event {
      background: #fffdfa;
      padding: 15px 20px;
      // margin-top: 15px;
      border-radius: 10px;
      width: 100%;
      .preselection_header,
      .calendar_header {
        display: flex;
        align-items: center;
        .heart-circle-boldt_icon,
        .calendar-days_icon {
          margin-right: 15px;
          width: 50px;
          height: 50px;
          border-radius: 100px;
          background: rgba(240, 101, 26, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f0651a;
        }
  
        .title {
          font-family: "faune text";
          font-size: 22px;
          font-weight: bold;
        }
      }
      .preselections_cards {
        width: 100%;
        margin-top: 30px;
        .cards_partenairs,
        .cards_participants {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fffdfa;
          padding: 10px 20px;
          margin-top: 15px;
          border-radius: 5px;
          border: 1px solid #ff6600;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          .identity_partenairs,
          .identity_participants {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            width: 100%;
  
            img {
              width: 55px;
              height: 55px;
              border-radius: 50px;
              margin-right: 15px;
            }
  
            .name_and_culture {
              display: flex;
              flex-direction: column;
  
              .name {
                font-size: 15px;
                font-weight: bold;
                color: #696969;
              }
  
              .culture {
                color: #ff6600;
                font-size: 10px;
              }
            }
          }
  
          .message {
            a {
              color: #ff6600;
            }
          }
        }
      }
  
      .all_preselections_buttons {
        a {
          display: inline-block;
          font-size: 15px;
          font-weight: bold;
          margin-top: 25px;
          color: #696969;
          .arrow-right-long_icon {
            margin-left: 10px;
            color: #f0651a;
          }
        }
      }
    }
  
    .calendar_event {
      margin-top: 20px;
    }
  
    .btn_matches,
    .btn_amoureux {
      display: inline-block;
      text-align: center;
      width: 100%;
      background: #f0651a;
      margin-top: 25px;
      padding: 15px 0;
      border-radius: 120px;
      color: #fff;
    }

  @media screen and (max-width: 1200px) {
    .content_event {
      display: flex;
      flex-direction: column;
    }
    .event_cards {
      width: 100%;
      height: 100%;
      overflow: initial;
      padding: 10px 20px;
      flex: 1;
  
      .event_name {
        display: initial;
        margin-top: 20px;
        margin-bottom: 15px;
  
        .event_title {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  
    .event_information_view {
      width: 100%;
      height: 100%;
      overflow: initial;
      border-radius: 0px;
      padding: 10px 20px;
      flex: 2;
      position: absolute;
      right: 100%;
      top: 0;
    }
  
    .sup_info {
      width: 100%;
      height: 100%;
      padding: 10px 20px;
      flex: 3;
      background: #fff;
      position: absolute;
      top: 0;
      right: 100%;
    }
    .slide_in {
      right: 0;
      animation-duration: 0.5s;
      animation-name: slidein;
    }
  
    @keyframes slidein {
      from {
        right: 100%;
      }
  
      to {
        right: 0%;
      }
    }
    // .date{
    //   font-size: 20px;
    // }
  }
  
  @media screen and (min-width: 1540px) {
    .container-wrapper{
    //   // width: 100% !important;
    //   // padding-right: 15px !important;
    //   // padding-left: 15px !important;
    //   // margin-right: auto !important;
    //   // margin-left: auto !important;
  
      max-width: 2000px;
  
      .event_information_view{
        flex: 3 1 0;
      }
    }
  }
  
  // categories_events_styles
  
  .wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .title {
    font-size: 88px;
    font-family: "faune text";
    // text-align: center;
    font-weight: bold;
    color: #583e23;
  }
  
  .search {
    margin-top: 50px;
    width: 50%;
    input {
      height: 3rem;
      border-radius: 13px;
    }
  }
  
  .categories-header {
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-family: "faune text";
  }
  
  .categories_card,
  .list_events_card {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
  
    .category_card {
      background: #fff;
      border-radius: 20px;
      padding: 05px;
      width: 301px;
      margin: 15px;
  
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      .card_img {
        img {
          width: 100%;
          height: 150px;
          border-radius: 20px;
          object-fit: cover;
        }
      }
  
      .category_name {
        font-size: 20px;
        font-weight: bold;
        font-family: "ubuntu";
        margin: 18px 15px auto;
      }
  
      .nbr_category_events {
        font-size: 15px;
        margin: 3px 15px 10px;
      }
    }
  }
  
  .description {
    text-align: center;
    font-size: 16px;
    font-family: "ubuntu";
    margin-top: 10px;
  }
  
  // .list_events_card{
  //   gap: 10px;
  // }
  .event_card {
    width: 330px;
    height: 270px;
    border-radius: 10px;
    padding: 10px;
    background-image: url("https://picsum.photos/200/300");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    .card_information {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .event_date_and_event_participants {
        display: flex;
        background: #fff;
        padding: 05px 20px;
        border-radius: 25px;
        width: fit-content;
        display: flex;
        align-items: center;
        .event_date {
          margin-right: 10px;
          .calendar_icon {
            color: #f0651a;
            margin-right: 5px;
            font-size: 1rem;
          }
          .date {
            font-size: 14px;
          }
        }
  
        .event_participants {
          .users_icon {
            color: #f0651a;
            margin-right: 5px;
            font-size: 1rem;
          }
          .participants {
            font-size: 14px;
          }
        }
      }
  
      .event_information {
        background: #fff;
        border-radius: 10px;
        padding: 10px;
        .event_card_name_and_type {
          display: flex;
          justify-content: space-between;
          .event_card_name {
            font-size: 18px;
            font-weight: bold;
            font-family: "faune text";
            color: #f0651a;
          }
          .participants_and_type {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .type {
              .type_private,
              .type_public {
                font-size: 1rem;
              }
              .type_private {
                color: #419d78;
              }
              .type_public {
                color: #f0651a;
              }
            }
          }
        }
  
        .city {
          display: flex;
          align-items: center;
          font-size: 14px;
          .location_icon {
            font-size: 0.9rem;
            margin-right: 10px;
            color: #f0651a;
          }
        }
      }
    }
  }
  
  .filter_space {
    border-right: 2px solid black;
  }
  
  .table {
    thead {
      tr {
        th {
          font-size: 30px;
          font-family: "faune text";
        }
      }
    }
  
    td {
      .td_header {
        font-size: 16px;
        font-weight: bold;
      }
  
      .value {
        display: flex;
        align-items: center;
        span {
          font-size: 13px;
          display: inline-block;
          background: #fff;
          border-radius: 50px;
          padding: 5px 20px;
          margin-right: 15px;
        }
  
        input {
          width: 100%;
        }
      }
    }
  }
  
  // sidebar_style
  
  .navbar_ {
    display: flex;
    /* background: #fff; */
    height: 100vh;
    width: fit-content;
    position: fixed;
    z-index: 1000;
  }
  
  .navbar_ .sidebar {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: #fffdfa;
  }
  
  .navbar_ .sidebar .sidebar_menu {
    list-style: none;
  }
  
  .navbar_ .sidebar .sidebar_menu li {
    margin: 25px 0;
  }
  
  .navbar_ .sidebar .sidebar_menu li a {
    text-decoration: none;
    font-size: 19px;
    color: #696969;
  }
  
  .navbar_ .sidebar .sidebar_menu li:hover a {
    color: #ff6600;
    transition: all ease 0.2s;
  }
  .navbar_ .sidebar .sidebar_menu li i {
    font-size: 21px;
    margin-right: 30px;
    color: #696969;
  }
  
  .navbar_ .sidebar .sidebar_menu li:hover a i {
    color: #ff6600;
    transition: all ease 0.2s;
  }
  
  .navbar_ .sidebar .sidebar_menu li:hover a span {
    color: #ff6600;
    transition: all ease 0.2s;
  }
  .sub_menu {
    display: flex;
    position: absolute;
    background: #fffdfa;
    // left: -300%;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;
    z-index: 2;
    transition: all ease 0.5s;
    align-items: center;
    padding: 20px;
  }
  
  .sub_menu li {
    list-style: none;
    margin: 25px 0;
    span {
      margin-right: 15px;
    }
  }
  
  .sub_menu li a {
    text-decoration: none;
  }
  
  .slide_in_navbar {
    left: 100%;
    animation-duration: 0.5s;
    animation-name: slideInNavbar;
  }
  
  @keyframes slideInNavbar {
    from {
      left: -100%;
    }
  
    to {
      left: 100%;
    }
  }
  
  .responsive_navbar {
    display: none;
  }
  
  @media screen and (max-width: 900px) {
    .navbar_ {
      width: 100vw;
      bottom: -100%;
  
      .sidebar {
        width: 100%;
        padding: 25px;
        justify-content: flex-start;
        .sidebar_menu_item {
          a {
            span {
              margin-left: 20px;
            }
          }
        }
      }
    }
  
    .navbar_ .sub_menu {
      display: none;
    }
  
    .slide_responsive_in {
      bottom: 0;
      animation-duration: 0.5s;
      animation-name: slideInResponsiveNavbar;
    }
  
    @keyframes slideInResponsiveNavbar {
      from {
        bottom: -100px;
      }
  
      to {
        bottom: 0;
      }
    }
  
    .responsive_navbar {
      display: flex;
      width: 100%;
      height: 80px;
      background: #fffdfa;
      // position: absolute;
      bottom: 0;
      z-index: 20000;
      position: fixed;
      justify-content: space-between;
      align-items: center;
    }
    .responsive_navbar .i_wrapper {
      position: absolute;
      right: 50%;
      left: 50%;
      top: -1px;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff6600;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: #fff;
    }
    // .responsive_navbar .i_wrapper span {
    //   font-size: 2rem;
    //   color: #fff;
    // }
  
    .responsive_navbar .nav_link {
      margin: 20px 25px 0;
    }
  
    .responsive_navbar .nav_link i {
      color: #696969;
      font-size: 35px;
    }
  }
  