// customize some Bootstrap variables
$primary: darken(#428bca, 20%);
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap");
// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
//@import "~bootstrap/dist/css";
//@import "./app.scss" ;

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "./nav.scss";

@import "./css_perso.css";
//@import "./picker.min.css";
//@import "./selectize.bootstrap3.css";
@import "./token-input.css";
//@import url('https: //cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

/*
.form-check-input {
    //position: absolute;
    border: solid;
    margin-top: 0.3rem;
    margin-left: -1.3rem;
}
*/
input {
  padding: 10px;
  //width: 3%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

//      EMAILS
@import "./email/email";

//      UTILS
@import "./utils/variables";
@import "./utils/functions";
@import "./utils/mixins";

//      BASE
@import "./base/base";
@import "./base/typography";
@import "./base/icons";

//      COMPONENTS
@import "./components/container";
@import "./components/site-logo";
@import "./components/custom-btn";
@import "./components/custom-form";
@import "./components/custom-taginput";
@import "./components/custom-dropdown";
@import "./components/custom-select";
@import "./components/inner-link";
@import "./components/user-status";
@import "./components/tag";
@import "./components/modal";
@import "./components/btn";

//      LAYOUT
@import "./layout/accomplishment";
@import "./layout/chat";
@import "./layout/footer";
@import "./layout/formation-card";
@import "./layout/messaging-member";
@import "./layout/navbar";
@import "./layout/recommended-formation";
@import "./layout/section";
@import "./layout/steps-indicator";
@import "./layout/tags";
@import "./layout/today-word";
@import "./layout/user-card";
@import "./layout/user-profile";

//      PAGE
@import "./pages/landing-page";
@import "./pages/inscription";
@import "./pages/login";
@import "./pages/home";
@import "./pages/community";
@import "./pages/formation";
@import "./pages/messages";

//      VENDORS
@import "./vendors/taginput";
@import "./vendors/noui-slider";
@import "./owl.carousel.min.css";
@import "owl.theme.default.min.css";
@import "bootstrap-tagsinput.css";

@import "./jquery.fancybox.min.css";

@import "./owl.carousel.min.css";
@import "./owl.theme.default.min.css";
//      Stepper
@import "./stepper.scss";

// style

@import "./style__profil.css";
@import "./animator.css";

@import "./ajust__.css";
@import "./paiement/pricing.scss";

@import "./mobile.css";

@import "./kevin_style";

.custom-btn-profil-view {
  font-weight: 700;
  background: #ff6600;
  /* padding: .6rem 3rem !important; */
  outline: none;
  border: none;
  color: white;
  border-radius: 2rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  /* width: 232px !important; */
  font-size: 18px;
  /* margin-left: -22px !important; */
}

.form-check-input {
  position: static !important;
  border: solid;
  margin-top: 0.3rem;
  margin-left: -1.3rem;
}

input {
  padding: 10px;
  width: auto;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

.input-long {
  width: 100% !important;
}
#bloc-reset-password .inscription-page__header {
  background-color: white;
  margin-top: 15px;
}

.corp {
  background-color: white;
}

.message_1 {
  font-size: 40px;
  font-family: "Faune Text";
  font-weight: bold;
}

#felicitation {
  font-weight: bold;
}

.message_3 {
  font-family: "Faune Text";
  font-weight: bold;
}

#nom_utiliasteur {
  font-size: 28px;
  color: #ff8c42;
}

#remerciement {
  font-size: 24px;
}

.message_2 {
  font-size: 18px;
}

.btn_orange {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #ff8c42;
  color: #ffffff;
}

.message_4 {
  font-size: 14px;
}
.corp {
  background-image: url("../images/bg/bg_notification_large.png");
  background-size: 100% 100%;
}

@media (min-width: 1280px) {
  .corp {
    background-image: url("../images/bg/bg_notification_large.png");
  }
}

@media (max-width: 1279px) and (min-width: 992px) {
  .corp {
    background-image: url("../images/bg/bg_notification_petit.png");
  }
}

@media (max-width: 600px) {
  .corp {
    background-image: url("../images/bg/bg_notification_petit.png");
  }
}

//user_card
.user-card .card-header,
.user-card .card-footer {
  background: none !important;
  border: none !important;
}

@media (max-width: 992px) {
  .user-card {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 10px !important;
  }
}

.user-card .card-body {
  padding: 0 !important;
  width: 100%;
}

.user-card .card-footer button {
  margin: auto;
}

.list-user {
  /*flex-wrap: nowrap;
  overflow: auto;
  padding-bottom: 15px;
  scrollbar-width: thin;*/
}

/* Style the tab */
.tab {
  overflow: hidden;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  background-color: transparent;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active:not(.btn-submit-edit) {
  font-weight: bold;
  color: #ff8c42;
  border-bottom: 2px solid #ff8c42;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}

.tablinks {
  display: block !important;
}

.form_edit_profil {
  width: 100%;
}

#Apprendre {
  display: block;
}
.btn-submit-edit {
  float: right !important;
  background-color: #ff8c42 !important;
}

#profil_update_sexe,
#registration_profil_sexe,
#profil_update_birthAt,
#registration_update_birthAt,
#discover_registration_temporarySexe {
  padding-top: 0;
  padding-bottom: 0;
  height: calc(3em + 2px);
}

#Avatar {
  width: 100% !important;
}

.form-edit a.active {
  background-color: #ff8c42 !important;
  color: white !important;
}

.menu-edit-profil-mobile {
  display: none;
}

#open-menu-edit-profil-mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .menu-edit-profil {
    display: none;
  }
  .menu-edit-profil-mobile,
  #open-menu-edit-profil-mobile {
    display: block;
  }
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content,
.overlay-content-profil {
  position: relative;
  top: 10%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
  cursor: pointer;
}

.overlay a:hover,
.overlay a:focus {
  color: #ff8c42;
}

.overlay .active {
  background-color: #ff8c42 !important;
  color: white !important;
}

#open-menu-edit-profil-mobile {
  background-color: #ff8c42 !important;
  color: white !important;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 10px;
  border: none;
  z-index: 3;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 30px;
}
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.mots-saisie {
  float: right;
  font-size: 14px;
  margin-right: 3%;
}

.notif-mots {
  font-size: 14px;
}
.btn-prev {
  float: right;
}

#title-form-edit {
  display: flex;
}

.abc_1 {
  text-align: center !important;
}

.confirmation-mail-page__bg {
  background-image: url("../images/bg/confirmation-bg.jpg");
}
.verify-mail-page__bg {
  background-image: url("../images/bg/verify-bg.jpg");
}

@media screen and (max-width: 992px) {
  .confirmation-mail-page__bg,
  .verify-mail-page__bg {
    width: 100%;
    left: 0;
    display: block !important;
  }

  .confirmation-mail-page__bg::before,
  .verify-mail-page__bg::before {
    content: "";
    background: #f7d4bf;
  }
  .content-confirmation-mail .text,
  .content-verify-mail .text {
    z-index: 2;
  }
  .content-confirmation-mail p,
  .content-confirmation-mail h2 {
    color: black;
  }
}

.select2-search__field {
  width: 100% !important;
}

.select2-selection--single {
  height: calc(3em + 2px) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  border-radius: 13px !important;
}

.select2-results__options {
  font-size: 1rem !important;
}
#v-pills-langue fieldset:not(.languesParle_content fieldset) {
  display: none !important;
}

.list-hobby-ami fieldset {
  display: block !important;
}

.fix-top {
  position: fixed;
  top: 0;
}
#myNav {
  z-index: 1000;
}

/*
#fond-mobile-home {
  display:none
}
*/

/*

.hero__title {
  margin-top:200px
}
*/

/*
@media screen and (max-width: 992px){
  #fond-mobile-home {
    display: block;
    background-position: center;
    position: relative;
    width: 100%;
    background-image: url('../images/bg/hero-bg-mobile.png');
    height: 500px;
    background-repeat: no-repeat;
  }

  .hero::before {
    display: none;
  }
  .container-home {
    height: 80%;
  }
  .hero{
    display: block;
    margin-top: 0 !important;
    height: auto;
  
  }

  .list-user {
    margin: auto;
  }

  .landing-process {
    margin-top: 0;
  }

  .hero__title {
    margin-top:10px
  }

}
*/

@media screen and (min-width: 1300px) {
  .hero::before {
    top: 114px;
  }
}

@media screen and (max-height: 700px) {
  .hero::before {
    margin-top: 114px;
  }
}

.nav-tabs {
  padding: 20px;
}

.nav-tabs li a {
  padding: 20px;
}

.nav-tabs li .active {
  background-color: #245682 !important;
  color: white !important;
}

#nav-pills-desktop {
  background-color: #ffffff;
  border: 1px solid #ecbda0;
  border-radius: 0.25rem;
}

.metier_content legend {
  display: none;
}

#img-message {
  margin: auto;
  width: 30%;
}
#bloc-faire-don #message-contribution {
  text-align: center;
  padding: 0 5% 0% 10%;
  width: 50%;
}
#bloc-faire-don #bloc-input-monaie {
  padding: 0 10% 0% 5%;
  width: 50%;
}

#bloc-faire-don #text-message {
  color: #f98f4b;
  text-align: center;
  font-size: 2vw;
  padding: 8%;
  font-family: "Faune Text";
  font-weight: bold;
}

#bloc-faire-don #bloc-message {
  background-image: url("../images/don/bloc-message.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  padding-bottom: 50%;
}

#bloc-faire-don .bloc-input-valeur {
  border: 1px solid #c4cad1;
  margin-left: 5%;
  margin-right: 5%;
  background: linear-gradient(180deg, #ffffff 20%, #e1e5e9 80%);
  border-top: #fffefe;
  border-radius: 5%;
  box-shadow: -20px 20px 20px 0 #bbb5b5;
}

#bloc-faire-don #img-cadeau {
  margin-top: -20%;
  height: 40%;
  width: 40%;
}

#bloc-faire-don {
  padding-top: 10%;
  margin: auto;
}
#bloc-faire-don .row {
  margin: 0;
}

#bloc-faire-don .bloc-input-valeur div {
  color: #eb6430;
  text-align: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

#btn-confirmation {
  color: white;
  background-color: #eb6430;
  border-radius: 20px;
  height: 40px;
  width: 140px;
  border: none;
  margin: auto;
  font-family: "Ubuntu" !important;
  font-weight: bold !important;
}
#bloc-faire-don .text_niveau_1 {
  font-size: 2vw;
  font-family: "Ubuntu" !important;
  font-weight: bold !important;
}
#bloc-faire-don .text_niveau_2 {
  font-size: 1.5vw;
  font-family: "Ubuntu" !important;
  font-weight: bold !important;
}

#bloc-faire-don .text_niveau_3 {
  font-size: 1vw;
  font-family: "Ubuntu" !important;
  font-weight: bold !important;
}

#bloc-faire-don #bloc-input {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  #bloc-faire-don #message-contribution {
    display: none;
  }

  #bloc-faire-don #bloc-input-monaie {
    width: 100%;
    margin-top: 5%;
  }

  #bloc-faire-don .text_niveau_1 {
    font-size: 6vw;
  }
  #bloc-faire-don .text_niveau_2 {
    font-size: 4vw;
  }

  #bloc-faire-don .text_niveau_3 {
    font-size: 3vw;
  }

  #bloc-faire-don .bloc-input-valeur div {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}

#formaulaire-payement {
  border: 1px solid #bbb5b5;
  padding: 3% 5% 3% 5%;
  background-color: #f3f3f3;
  width: 60%;
  margin: auto;
}

@media screen and (max-width: 949px) {
  #formaulaire-payement {
    width: 95%;
  }
}

#formaulaire-payement #title-formulaire {
  text-align: center;
}

#formaulaire-payement #title-formulaire div,
#title-formulaire h3,
#title-formulairep {
  margin-bottom: 5%;
}

#formaulaire-payement #title-formulaire h3 {
  padding: -3% 3% 0% 3%;
}

#formaulaire-payement .title-formulaire {
  margin-bottom: 10%;
  font-weight: bold;
  font-family: "Ubuntu" !important;
}
#formaulaire-payement .numeration-title-form {
  border: 3px solid black;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

#formaulaire-payement #form-adresse {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#formaulaire-payement #formualire-footer {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

#formaulaire-payement #right-option {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  padding-bottom: 3%;
}

#formaulaire-payement #left-option .form-check {
  margin: 0;
  padding: 0;
  color: #eb6430;
  padding-top: 6%;
}

#formaulaire-payement #form-adresse select {
  background-color: #b5b5b5;
  text-align: center;
}

#formaulaire-payement #formualire-footer select {
  background-color: #504848;
  color: #fffefe;
}

#formaulaire-payement .form-check-input {
  margin-left: 5px !important;
}

#formaulaire-payement .form-check-label {
  font-size: 12px;
}
#formaulaire-payement input:not(.form-check-input) {
  width: 100% !important ;
}

#title-securise {
  font-size: 12px;
}

#lien-return {
  color: #eb6430 !important;
  font-size: 14px;
}

#header-payement {
  margin: 1% 3% 5% 3%;
}

#header-payement div:nth-child(2) {
  text-align: center;
}
#header-payement div:nth-child(2) img {
  padding: 10px 15px;
  border: 10px solid #eb6430;
  border-radius: 50%;
}

#header-payement div:nth-child(3) {
  text-align: right;
}

#payement_etape_3 {
  text-align: center;
}

#message-remerciement {
  margin: 2%;
  color: #084a10;
}

#btn-quitter-remerciement {
  color: #ff9100;
  background-color: #ffffff;
  border: 2px solid gray;
  padding: 0 2%;
  margin: auto;
}

#payement_etape_3 div:nth-child(1) {
  text-align: left;
  position: absolute;
  margin-left: 2%;
}

.bloc-progress-profil,
.bloc-menu-profil,
.bloc-user-profil {
  margin: 0 !important;
}

.icon-chevron-right {
  background-image: url("../images/icons/chevronright.png");
}
.icon-chevron-down {
  background-image: url("../images/icons/chevrondown.png");
}

.icon-nav {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  display: block;
  float: right;
  padding: 0.75rem 1.25rem;
}

.custom-calendar {
  background-color: #ffffff;
}

.item-centred {
  text-align: center !important;
}
.toast.show {
  display: block;
  position: fixed;
  bottom: 1%;
  left: 1%;
}
.toast {
  z-index: 20000;
  display: none;
}

.nav-bar-autho-v {
  border-color: #ff6600;
}

a {
  text-decoration: none !important;
}

.tagify {
  width: 100%;
  max-width: 700px;
}

.btn-resolution {
  color: #212529;
  background-color: #c8e3bc !important;
  border-color: #c8e3bc !important;
}

.btn-resolution:hover {
  color: #212529;
  background-color: #bfdfb2;
  border-color: #bfdfb2;
}

.btn-resolution:active {
  color: #212529;
  background-color: #bfdfb2;
  border-color: #bfdfb2;
}

///// Prix //////
.carte-prix {
  position: absolute;
  top: -2.5rem;
  right: -0.5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #dc3545;
  padding: auto;
  padding-top: 23px;
  color: white;
}
.header-prix {
  min-height: 11rem;
  padding-top: 20%;
}

.info_ayant_enfant {
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
}
.profilActivityPractice_content .col-form-label {
  display: none;
}

.socialBenefit label,
.personalBenefit_content label,
.threeWordsGovernLife_content label {
  display: none;
}

#btn-profilActivityPractice-add {
  margin: 20px;
}

#btn-threeWordsGovernLife-add {
  margin-bottom: 20px;
}

.home-nav__links > li {
  float: left;
}

.home-nav__links li ul {
  margin-top: -45px;
  margin-left: 14.5rem;

  display: none;
  position: absolute;
  border-top-right-radius: 15px 15px;
  border-bottom-right-radius: 15px 15px;
  width: 13em;
  background-color: #f8f9fa;
}

@media screen and (max-width: 767px) {
  .home-nav__links li ul {
    display: none;
    position: absolute !important;
    bottom: 4em;
    left: -65px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100%;
    background-color: #f8f9fa;
  }
}

@media screen and (max-width: 1199px) {
  .home-nav__links li ul {
    margin-left: 12.5rem;
  }
}

@media screen and (max-width: 991px) {
  .home-nav__links li ul {
    margin-left: 4rem;
  }
}

.home-nav__links li:hover > ul {
  display: block;
  cursor: pointer;
}
