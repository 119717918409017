.btn-p-primary{
    $self: &;
    font-weight: bold;
    background: $colour-primary;
    padding: .3rem 1.2rem !important;
    outline: none;
    border: none;
    color: white;
    border-radius: 2rem;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
    margin-bottom: 10px;





    @include media(large){
        padding: .6rem 2rem;
    }

    &:hover{
        background-color: desaturate(darken($colour-primary, 10%), 10%);
    }

    &--outline{
        color: $colour-link;
        background: transparent;
        border: 1px solid $colour-link;

        &:hover{
            color: darken($colour-link, 20%);
            border-color: darken($colour-link, 20%);
            background-color: transparent;
        }

        &#{$self}--secondary{
            color: $colour-primary;
            border-color: $colour-primary;

            &:hover{
                color: desaturate(darken($colour-primary, 10%), 10%);;
                border-color: desaturate(darken($colour-primary, 10%), 10%);;
            }
        }
    }
    &--white{
        background-color: white;
        color: $colour-primary;
        border: none;

        &:hover{
            color: desaturate(darken($colour-primary, 10%), 10%);
            background-color: white;
        }
    }

    &--valid{
        
        background-color: #28a745;

        &:hover{
        background-color: #28a745;;
    }
    }
}