ul.token-input-list {
    overflow: hidden; 
    height: auto !important; 
    height: 1%;
    width: 400px;
    border: 1px solid #8496ba;
    cursor: text;
    font-size: 12px;
    font-family: Verdana;
    min-height: 1px;
    z-index: 999;
    margin: 0;
    padding: 0;
    background-color: #fff;
    list-style-type: none;
    clear: left;
}

ul.token-input-list li input {
    border: 0;
    width: 100px;
    padding: 3px 8px;
    background-color: white;
    margin: 2px 0;
    -webkit-appearance: caret;
}

li.token-input-token {
    overflow: hidden; 
    height: auto !important; 
    height: 15px;
    margin: 3px;
    padding: 1px 3px;
    background-color: #eff2f7;
    color: #000;
    cursor: default;
    border: 1px solid #ccd5e4;
    font-size: 11px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    float: left;
    white-space: nowrap;
}

li.token-input-token p {
    display: inline;
    padding: 0;
    margin: 0;
}

li.token-input-token span {
    color: #a6b3cf;
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
}

li.token-input-selected-token {
    background-color: #5670a6;
    border: 1px solid #3b5998;
    color: #fff;
}

li.token-input-input-token {
    float: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

div.token-input-dropdown {
    position: absolute;
    width: 400px;
    background-color: #fff;
    overflow: hidden;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    cursor: default;
    font-size: 11px;
    font-family: Verdana;
    z-index: 1;
}

div.token-input-dropdown p {
    margin: 0;
    padding: 5px;
    font-weight: bold;
    color: #777;
}

div.token-input-dropdown ul {
    margin: 0;
    padding: 0;
}

div.token-input-dropdown ul li {
    background-color: #fff;
    padding: 3px;
    margin: 0;
    list-style-type: none;
}

div.token-input-dropdown ul li.token-input-dropdown-item {
    background-color: #fff;
}

div.token-input-dropdown ul li.token-input-dropdown-item2 {
    background-color: #fff;
}

div.token-input-dropdown ul li em {
    font-weight: bold;
    font-style: normal;
}

div.token-input-dropdown ul li.token-input-selected-dropdown-item {
    background-color: #3b5998;
    color: #fff;
}