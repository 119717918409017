/*--------------------------Footer en bas--------------------------------------*/
html {
  position: relative;
}

body {
  min-height: 100vh;
  /*margin-bottom: 60px; /* Margin bottom by footer height */
}

footer {
  /*position: absolute;*/
  /*bottom: 0;*/
  /*width: 100%;
  /*height: 10px; /* Set the fixed height of the footer here */
  /*line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

/*--------------------------------------------Ancien UWANDZANI------------------------------------*/

.colonne {
  border: solid;
  text-align: center;
}

.div-longue {
  min-height: 100vh;
}

.miniature{
  font-size: 1.5em;
}

h1, h2, h5, h3 {
  text-align: center;
}


.responsiveImg {
  width: 100%;
  height: auto;
}


.Partie_Cours{
  padding-top: 2em;
}

.case1{
  text-align: center;
  border: solid;
  border-color: #BF7225;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin: 0.5rem;
  height: 100%;
  width: 100%;
}

.case2{
  height: 10em;
  text-align: center;
  border: solid;
  width: 5em;
  border: solid;
  border-color: blue;
  margin: 0.5em;
}

.Contenu_Page{
  border: solid;
  border-color: red;
}


.case1_Lecon{
  height: 13em;
  text-align: center;
  border: solid;
  width: 100%;
  border: solid;
  border-color: green;
  margin: 0.5em;
}

.CasGenerale{
  text-align: center;
  width: 99%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

button{
  display: block;
  margin:auto;
}

.Propositions{
  margin-top: 5em;
  margin-bottom: 1em;
}

.saisi_mot{
  display: block;
  margin:auto;
}

.fade {
opacity: 1;
}

/*
li {
list-style-type: none;
}
*/

/*------------------------------------------------CSS-----------------------------------*/
/* Chat chat_containers */
.chat_container {
border: 2px solid #dedede;
background-color: #f1f1f1;
border-radius: 5px;
padding: 10px;
margin: 10px 0;
}

/* Darker chat chat_container */
.darker {
border-color: #ccc;
background-color: #ddd;
}

/* Clear floats */
.chat_container::after {
content: "";
clear: both;
display: table;
}

/* Style images */
.chat_container img {
float: left;
max-width: 60px;
width: 100%;
margin-right: 20px;
border-radius: 50%;
}

/* Style the right image */
.chat_container img.right {
float: right;
margin-left: 20px;
margin-right:0;
}

/* Style time text */
.time-right {
float: right;
color: #aaa;
}

/* Style time text */
.time-left {
float: left;
color: #999;
}

#liste_message {max-height: 85%;overflow-y: scroll;}
#chat-wrapper {
max-height: 700px;
overflow: hidden;
}
.chat_container.darker.unread {
  background-color: rgba(0, 123, 255, 0.07) !important;
}

#formulaire .action {
  vertical-align: middle;
  margin: auto;
}
.card.card-primary {
  border: solid 1px #007bff;
  margin-bottom: 10px;
  border-radius: 5px;
}
.card.card-secondary {
  border: solid 2px pink;
  margin-bottom: 10px;
  border-radius: 5px;
}
.pagination {
  justify-content: center;
  margin-top: 10px;
}


/*Profill*/

.user-card-profil:before {
  content: "";
  z-index:999;
  position: absolute;
  right: 40px;
  top: 190px;
  height: 30px;
  width: 30px;
  border:5px solid white;
  border-radius: 50%;
  background-color: #4cd137;
}
.user-card-profil.online:before{
  background-color: #4cd137;
}
.user-card-profil.offline:before{
  background-color: #a2a7a1;
}