.user-status {
    position: relative;
    float: right;
    right: 25px;
    bottom: 0;
    top: -30px;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #d7bcbc;
    // background-color: #419D78;
    border: 3px solid white;
    border-radius: 50%;
    &--online {
        background-color: lighten($colour-fourth, 20%);
    }
    &--large {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.user-status-online {
    position: relative;
    float: right;
    right: 25px;
    bottom: 0;
    top: -30px;
    width: 1.5rem;
    height: 1.5rem;
    // background-color: #d7bcbc;
    background-color: #419D78;
    border: 3px solid white;
    border-radius: 50%;
    &--online {
        background-color: lighten($colour-fourth, 20%);
    }
    &--large {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.user-status-decouvrir {
    position: relative;
    float: right;
    right: 41px;
    bottom: 0;
    // top: 165px;
    width: 1.3rem;
    height: 1.3rem;
    background-color: #d7bcbc;
    border: 3px solid white;
    border-radius: 50%;
}

.user-status-decouvrir-online {
    position: relative;
    float: right;
    right: 41px;
    bottom: 0;
    // top: 165px;
    width: 1.3rem;
    height: 1.3rem;
    background-color: #419D78;
    border: 3px solid white;
    border-radius: 50%;
}