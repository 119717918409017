.footer{
    margin-top: 4rem;
    background: $colour-third;
    

    &__img{
        width: 10rem;

        @include media(small){
            width: 7rem;
        }
    }
    &__container-links{
        align-self: center;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @include media(small){
            justify-content: space-between;
        }
        @include media(mobile){
            flex-direction: column;
        }
    }
    &__section{
        margin-left: 3rem;

        @include media(small){
            margin-left: 0;
        }
        @include media(mobile){
            margin-top: 2rem;
        }
    }
    &__links{
        list-style: none;
        margin-top: 1.5rem;

        & li{
            margin-top: 1rem;
        }
        & a {
            color: white;
            @include small-text-rfs;
        }
    }
    &__socials{
        list-style: none;
        position: relative;
        margin-bottom: 0;

        display: flex;
        justify-content: center;

        & li{
            margin: 1rem;
        }
        & a {
            color: white;
        }

        &::before{
            content: "";
            
            width: 40%;
            height: 1px;
            background: white;
            align-self: center;
            justify-self: flex-start;
        }
        &::after{
            content: "";
            width: 40%;
            height: 1px;
            background: white;
            align-self: center;
            justify-self: flex-end;
        }
    }
    &__copyright{
        color: white;

        @include small-text-rfs;
    }

}