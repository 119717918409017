.formation-page{
    &__list{
        display: grid;
        grid-gap: 0.75rem;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
    &__tags{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 100%;
        
        & li {
            background-color: rgba($colour-primary, 0.2);          
            padding: 0.3rem 1rem;
            margin-right: 0.4rem;
            border-radius: 1rem;
            @include p-rfs;
        }
        & a {
            color: $colour-primary;

            &:hover{
                text-decoration: none;
            }
        }
        & .selected{
            background-color: rgba($colour-fourth, 0.2);  
            
            & a {
                color: $colour-fourth;
            }
        }
    }
}