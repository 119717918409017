.user-profile{
    $self: &;
    width: 100%;
    height: 100%;
    position: relative;

    &__wrapper{
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 20rem;
        padding-top: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include media(large){
            max-width: 100%;
        }
    }
    &__close{
        position: absolute;
        top: 1.8rem;
        left: 1.5rem;
        color: $colour-primary;
        font-size: 1.375rem;
        cursor: pointer;
        z-index: $navbar-z-index + 3;
    }
    &__avatar{
        display: flex;
        justify-content: center;
        align-items: center;

        & img{
            width: 10rem;
            border-radius: 50%;
        }
    }
    &__details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    &__gender, &__age{
        color: $colour-link;
        @include small-text-rfs;
    }
    &__description{
        text-align: center;
    }
    &__label{
        @include small-text-rfs;
        font-weight: bold;
    }

    &--large{
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        z-index: $navbar-z-index + 2;
        transform: translateX(100%);
        transition: transform .3s ease-in-out .1s;
        background-color: white;
        box-shadow: -3px 0 3px rgba($color: #000000, $alpha: 0.06);

        &#{$self}--show{
            display: block;
            transform: translateX(0%);
            border-radius: 0;
        }
    }
}