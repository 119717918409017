@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

@font-face {
    font-family: 'Faune Text';
    src: url(base-url("../fonts/Faune/Faune-Text_Regular.woff"));
    font-weight: normal;
}

@font-face {
    font-family: 'Faune Text';
    src: url(base-url("../fonts/Faune/Faune-Text_Bold.woff"));
    font-weight: bold;
}

.faune-text{
    font-family: 'Faune Text' !important;
    font-weight: normal;

    &--regular{
        font-weight: normal !important;
    }
    &--bold{
        font-weight: bold !important;
    }
}

.ubuntu{
    font-family: 'Ubuntu' !important;
    font-weight: normal;

    &--regular{
        font-weight: normal !important;
    }
    &--bold{
        font-weight: bold !important;
    }
}

body{
    -webkit-font-smoothing: antialiased;  
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: $colour-text;
}

h1{
    font-family: 'Faune Text', sans-serif;
    font-weight: bold;
    color: $colour-secondary;

    @include h1-rfs;
}

h2{
    font-family: 'Faune Text', sans-serif;
    font-weight: bold;
    
    @include h2-rfs;
}

h3{
    font-family: 'Faune Text', sans-serif;
    font-weight: bold;
    
    @include h3-rfs;
}

h4{
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    
    @include h4-rfs;
}

p {
    font-family: 'Ubuntu', sans-serif;
    font-weight: normal;
    
    @include p-rfs;
}

label {
    @include p-rfs;
}

button{
    @include p-rfs;
}