.today-word{
    background-color: white;
    border-radius: 1.5rem;
    padding: 2rem 2.5rem;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDIyIiBoZWlnaHQ9IjI5MiIgdmlld0JveD0iMCAwIDQyMiAyOTIiPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzYzIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA2MyIgd2lkdGg9IjQyMiIgaGVpZ2h0PSIyOTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExMjAyIC03NjUpIiBmaWxsPSJub25lIi8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBpZD0iTWFza19Hcm91cF80IiBkYXRhLW5hbWU9Ik1hc2sgR3JvdXAgNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMjAyIDc2NSkiIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzciIGRhdGEtbmFtZT0iUGF0aCA3IiBkPSJNMCwzLjkyOUMwLDMuMTYzLjAxMSwyLjQuMDI5LDEuNjM4QTk5LjI0Miw5OS4yNDIsMCwwLDEsMTgsMGM1My41NzEsMCw5Nyw0Mi42NTUsOTcsOTUuMjczLDAsLjc2Ni0uMDEyLDEuNTI5LS4wMywyLjI5QTk5LjIzMSw5OS4yMzEsMCwwLDEsOTcsOTkuMkM0My40MjgsOTkuMiwwLDU2LjU0NywwLDMuOTI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTE2NTIuNjI2IC03MjUpIHJvdGF0ZSg5MCkiIGZpbGw9IiM0MTlkNzgiLz4KICAgIDxlbGxpcHNlIGlkPSJFbGxpcHNlXzgiIGRhdGEtbmFtZT0iRWxsaXBzZSA4IiBjeD0iOTQiIGN5PSI5NS41IiByeD0iOTQiIHJ5PSI5NS41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTI0MiAtODUwKSIgZmlsbD0iIzQxOWQ3OCIvPgogICAgPHBhdGggaWQ9IlBhdGhfOCIgZGF0YS1uYW1lPSJQYXRoIDgiIGQ9Ik0xMTUsMy45MjljMC0uNzY2LS4wMTEtMS41MjgtLjAyOS0yLjI5QTk5LjI0Miw5OS4yNDIsMCwwLDAsOTcsMEM0My40MjksMCwwLDQyLjY1NSwwLDk1LjI3M2MwLC43NjYuMDEyLDEuNTI5LjAzLDIuMjlBOTkuMjMxLDk5LjIzMSwwLDAsMCwxOCw5OS4yQzcxLjU3Miw5OS4yLDExNSw1Ni41NDcsMTE1LDMuOTI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTE1NjYuMTkzIC04MTQpIHJvdGF0ZSg5MCkiIGZpbGw9IiNmZjhjNDIiLz4KICA8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;

    @include media(small){
        padding: 15px 1.5rem;
    }

    @include media(extra-small){
        background-size: 60%;
    }

    &__label{
        color: $colour-primary;
    }
    &__word{
        margin-top: -0.5rem;
    }
    &__definition{
        max-width: 40rem;

        @include media(extra-small){
            max-width: 28rem;
        }
    }
    &__origin{
        & b {
            color: $colour-third;
        }
    }
}