.home-page {
  $self: &;
  background-color: $bg-page-logged;

  &__content {
    //padding-left: map-get($map: $nav-logged-padding, $key: xl);
    padding-left: 05rem;

    @include media(large) {
      padding-left: map-get($map: $nav-logged-padding, $key: lg);
    }
    @include media(medium) {
      padding-left: map-get($map: $nav-logged-padding, $key: md);
    }
    @include media(small) {
      padding-left: map-get($map: $nav-logged-padding, $key: sm);
    }
  }
  &__header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: $bg-page-logged;

    &--scroll {
      border-bottom: 1px solid #eaeaea;
      background-color: white;
    }
  }
  &__collapse {
    max-height: 100%;

    @include media(small) {
      max-height: 0;
      overflow: hidden;
    }

    &--visible {
      max-height: 100%;
      padding-bottom: 1rem;
    }
  }
  &__filter {
    font-size: 1.9rem;
    height: 3.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colour-primary;
    cursor: pointer;
  }
}

.error_steep {
  text-align: center;
  color: red;
}

.manque {
  border-color: red;
}

.input-sans-padding {
  height: calc(3em + 2px);
  padding: 0 1em 0 1em;
}

.after-img-change {
  height: 100%;
  width: 100%;
}

.languesParle_content .col-form-label {
  display: none;
}

#applicaltion-section {
  background-color: $colour-fourth;
  //background-image: url("../../images/bg/bg-img-application.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.text-white {
  color: white;
}
.text-application {
  //border: solid;
  border-radius: 2em;
  background-color: #67b093;
}
.bottom-bas {
  //position: absolute;
  margin-bottom: 0.3em !important;
}
#bold {
  font-weight: bold;
}
