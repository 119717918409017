#v-pills-tab{
   margin-top: 48px;
}

#mymenu1{
    margin-top: 48px;
}

.fix-top1{
    position: fixed;
    top: 0;
    z-index: 1000;
}


#open-menu-edit-profil-mobile{
    /* margin: -2px -1% 0px -20px !important;*/
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important; 
}

.titre__{
    font-family: 'Ubuntu' !important;
    margin: 2%;
    font-size: 35px;
}

table{
    margin: 2% 2% 2% 0 !important;
}

.col__left{
    width: 20% !important;
}

.circle__img__profil{
    /* box-shadow: 0 0.5px 2px 0.5px rgba(128, 128, 128, 0.719); */
    width: 250px;
    height: 250px;
    border: solid 3px orange;
}
    

#professionel{
    background-color: inherit !important;
    border-radius: 0;
    box-shadow: 0 0 0 0 rgba(128, 128, 128, 0.719);
}

.id_tab-left{
    background-color: white;
    border: solid 1px orange;
    border-radius: 5px;
    margin-left: 10px;
}

.on__screen1{ 
    position: fixed !important;
    width: 18%;
    height: 80vh;
    left: 17%; /* on positionne l'élément à la moitié de l'écran */
    margin-top: calc(0%/-2);
}

.id_tab-right{
    padding: 0 15px 1% 15px !important;
    background-color: white;
    border: solid 1px orange;
    border-radius: 5px;
}

.formation-card{
    height: 250px !important;
    /* width: 300px !important; */
}

@media screen and (max-width : 992px) {

    .pills-tab {
      display: none !important;
    }

    #myNav{
        display: block !important;
    }

    .col-cont{
        /* padding: 2% 3%; */
    }

    .owl-carousel{
        padding: 5%
    }

    #containt{
        /* margin-left: -500px; */
        width: 100% !important;
        position: relative;
        left: 3%;
        margin: 20px 0 0 15px !important;
        padding: 0;
    }

  }

  .line__{
        position: relative;
        bottom: 0px;
      
  }

  .img__caroussel{
      /*height: 100%;*/
  }
  .inscription-page__submit button {
 /* width: 60% !important;*/
  max-width: 16rem;
  padding:12px;
}
.login-page__login {
    font-weight: bold;
    color: #9B9B9B;
    font-size: 1.1rem;
    width: 63% !important;
	padding:12px !important;
}
.inscription-page__submit button {
  width: 232px !important;
max-width: 16rem;}

  @media screen and (max-width : 767px) {
    .btn{
       /* margin-left: 30% !important*/
    }
	

  }