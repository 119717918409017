.formation-card {
  $self: &;
  position: relative;
  height: 17rem;
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  margin-bottom: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.4;
    z-index: -1;
    transition: all 0.2s linear;
  }
  &::after {
    content: attr(data-badge);
    position: absolute;
    top: 3%;
    right: 4%;
    padding: 1px 13px;
    border-radius: 18px;
    pointer-events: none;
    display: block;
    line-height: 1.1;
    z-index: 1;
    @include very-small-text-rfs;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -2;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: white;
    font-weight: bold;
    transition: all 0.2s ease;
    word-break: break-word;
    overflow-wrap: break-word;
    @include h4-rfs;
  }
  &__category {
    color: white;
    display: block;
    transition: all 0.2s ease;
    @include small-text-rfs;
  }

  &__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    transition: all 0.3s ease;
  }
  &__visible {
    transition: all 0.3s ease;
  }
  &__hover {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    margin-top: 1rem;
  }
  &__description {
    color: white;
    transition: all 0.3s ease;
    word-break: break-word;
    overflow-wrap: break-word;
    @include small-text-rfs;
  }
  &__exercice {
    display: none;
  }
  &__chapter {
    display: none;
  }

  &:hover {
    &::before {
      opacity: 0.8;
    }
    & #{$self} {
      &__caption {
        transform: translateY(0%);
      }
      &__hover {
        height: 100%;
        opacity: 1;
      }
    }
  }

  @include formation-card-badge;
}

.payant {
  background-image: url(/images/user/cadena-u.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100% !important;
  height: 100%;
  padding-top: 43%;
  background-size: 30%;
}

.payant_caption {
  height: 100%;
  width: 100% !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .payant_card_hover {
    margin-top: -21px;
  }
}
.owl-carousel-formation {
  width: auto;

  .owl-item {
    & img {
      width: auto;
      height: 100%;
    }
  }
}

.payant {
  background-image: url("../../images/user/cadena-u.png");
  background-repeat: no-repeat;
}
