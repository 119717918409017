//  COLORS
$colour-primary: #FF6600;
$colour-secondary: #583e23;
$colour-third: #bf7225;
$colour-fourth: #419d78;


$colour-link: #9B9B9B;
$colour-text: #696969;
$colour-title: #626262;
$colour-eval: #DC3545;
$bg-page-logged: #FFF1DC; /* #FEEADC     #F7F7F7  https://webdi.fr/couleur-hexa.php?c=ffebcd*/
$form-border-color: #E8E7E7;
$form-radius: 13px;
$navbar-z-index: 10000;
$scroll-bar: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0);

$colour-font-message:#E8E7E7;
$colour-search-wrapper: #FFFDFA;
$bg-page-logged-item: #fae4c3; 


//  CONFIGS
$min-height: 600px;
$assets-base-url: '../';


//  BREAKPOINTS
$breakpoints:(
    ultra-large: 1440px,
    extra-large: 1366px,
    large: 1199px, // lg
    medium: 991px, // md
    small: 767px, // sm
    extra-small: 575px, // xs
    mobile: 480px,
    tiny: 320px
);

// INSCRIPTION BG IMAGES
$inscription-steps:(
    1: (bg: 'inscription-1.jpg'),
    2: (bg: 'inscription-2.jpg'),
    3: (bg: 'inscription-3.jpg'),
    4: (bg: 'inscription-4.jpg'),
    5: (bg: 'inscription-5.jpg'),
    6: (bg: 'inscription-6.jpg'),
    7: (bg: 'inscription-7.jpg'),
    login: (bg: 'login.jpg')
);

// USER CARD BADGE
$user-badge:(
    new: (background-color: $colour-fourth, color: white, border: none),
    super: (background-color: white, color: $colour-third, border: 1px solid $colour-third),
    premium: (background-color: $colour-primary, color: white, border: none),
);

// FORMATION CARD BADGE
$formation-badge:(
    new: (background-color: $colour-fourth, color: white, border: none),
    super: (background-color: white, color: $colour-third, border: 1px solid $colour-third),
    premium: (background-color: $colour-primary, color: white, border: none),
);



// PADDING NAVBAR ON LOGGED
$nav-logged-padding: (
    xl: 16rem,
    lg: 13rem,
    md: 5rem,
    sm: 0
);


//  UWANDZANI ICONS
$icomoon-font-family: "uwandzani" !default;
$icomoon-font-path: "../../fonts/icons" !default;

$icon-close: "\e905";
$icon-filter: "\e904";
$icon-accomplishment: "\e900";
$icon-search: "\e901";
$icon-send: "\e902";
$icon-menu: "\e903";
$icon-community: "\e906";
$icon-notification: "\e907";
$icon-home: "\e908";
$icon-messages: "\e909";
$icon-formation: "\e90a";
$icon-arrow-left2: "\ea40";
$icon-facebook: "\ea90";
$icon-instagram: "\ea92";
$icon-twitter: "\ea96";
$icon-profilActive: url("./../../../assets/fonts/icons/person_active.svg");
$icon-profilDesactive: url("./../../../assets/fonts/icons/person_desactive.svg");
$icon-actualiteActive: url("./../../../assets/fonts/icons/actualite_active.svg");
$icon-actualiteDesactive: url("./../../../assets/fonts/icons/actualite_desactive.svg");
$icon-conversationActive: url("./../../../assets/fonts/icons/conversation_active.svg");
$icon-conversationDesactive: url("./../../../assets/fonts/icons/conversation_desactive.svg");
$icon-exerciceActive: url("./../../../assets/fonts/icons/exercice_active.svg");
$icon-exerciceDesactive: url("./../../../assets/fonts/icons/exercice_desactive.svg");
$icon-gratitudeActive: url("./../../../assets/fonts/icons/gratitude_active.svg");
$icon-gratitudeDesactive: url("./../../../assets/fonts/icons/gratitude_desactive.svg");
$icon-storyActive: url("./../../../assets/fonts/icons/story_active.svg");
$icon-storyDesactive: url("./../../../assets/fonts/icons/story_desactive.svg");
$icon-languageActive: url("./../../../assets/fonts/icons/language_active.svg");
$icon-languageDesactive: url("./../../../assets/fonts/icons/language_desactive.svg");
$icon-cloche: url("./../../../assets/fonts/icons/bell.svg");

