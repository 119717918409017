.recommended-formation{
    $self: &;
    background-color: $colour-secondary;
    border-radius: 1.5rem;
    padding: 2rem 2.5rem;
    color: white;
    overflow: hidden;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include media(extra-small){
        border-radius: 0;
        padding: 15px 1.5rem;
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba($color: #000000, $alpha: 0.6);

        @include media(small){
            background: rgba($color: #000000, $alpha: 0.7);
        }
    }
    &:hover{
        & #{$self}__img{
            transform: scale(1.05);
        }
    }
    &__img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s ease-out;
    }
    &__wrapper{
        position: relative;
        z-index: 1;
    }
    &__category{
        @include small-text-rfs;
    }
    &__description{
        max-width: 27rem;

        @include media(large){
            max-width: 25rem;
        }
        @include small-text-rfs;
    }
    &__side{
        display: flex;
    }
    
    /*
    &__exercice{
        position: absolute;
        
        background-color: $colour-fourth;
        
        color: white;
        
        border-radius: 50%;
        text-align: center;
        width: 3rem;
        height: 3rem;
        bottom: 5%;
        right: 2%;
        z-index: 1;
        line-height: 2;
        @include small-text-rfs;

        @include media(small){
            position: relative;
            margin-left: 0.3rem;
        }
    }
    */
    
    &__Exo{
        position: absolute;
        background-color: $colour-fourth;
        
        color: white;
        border-radius: 50%;
        text-align: center;
        width: 3rem;
        height: 3rem;
        bottom: 5%;
        right: 2%;
        z-index: 1;
        line-height: 2;
        padding-top: 0.4rem;
        @include small-text-rfs;

        @include media(small){
            position: relative;
            margin-left: 0.3rem;
        }
    }

    &__Eval{

        background-color: $colour-eval;
        position: absolute;
        color: white;/*$colour-text;*/
        border-radius: 50%;
        text-align: center;
        width: 3rem;
        height: 3rem;
        bottom: 5%;
        right: 2%;
        z-index: 1;
        line-height: 2;
        padding-top: 0.4rem;
        @include small-text-rfs;

        @include media(small){
            position: relative;
            margin-left: 0.3rem;
        }        
    }

    &__Lang{
        position: absolute;
        background-color: #ff8c42; /*white;*/
        color: white;/*$colour-text;*/
        border-radius: 50%;
        text-align: center;
        width: 3rem;
        height: 3rem;
        bottom: 5%;
        right: 2%;
        z-index: 1;
        line-height: 2;
        padding-top: 0.4rem;
        @include small-text-rfs;

        @include media(small){
            position: relative;
            margin-left: 0.3rem;
        }
    }

    &__Cult{

        background-color: $colour-third;
        position: absolute;
        color: white;/*$colour-text;*/
        border-radius: 50%;
        text-align: center;
        width: 3rem;
        height: 3rem;
        bottom: 5%;
        right: 2%;
        z-index: 1;
        line-height: 2;
        padding-top: 0.4rem;
        @include small-text-rfs;

        @include media(small){
            position: relative;
            margin-left: 0.3rem;
        }        
    }

    &__CS{

        background-color: $colour-third;
        position: absolute;
        color: white;/*$colour-text;*/
        border-radius: 50%;
        text-align: center;
        width: 3rem;
        height: 3rem;
        bottom: 5%;
        right: 2%;
        z-index: 1;
        line-height: 2;
        padding-top: 0.4rem;
        @include small-text-rfs;

        @include media(small){
            position: relative;
            margin-left: 0.3rem;
        }        
    }
}
