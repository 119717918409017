.custom-select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    min-height: 3.1rem;
    border-radius: $form-radius;
    border: 1px solid $form-border-color;

    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC44MjgiIGhlaWdodD0iMTMuODI4IiB2aWV3Qm94PSIwIDAgMjQuODI4IDEzLjgyOCI+CiAgPGcgaWQ9Ikdyb3VwXzMxOCIgZGF0YS1uYW1lPSJHcm91cCAzMTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjUzLjA4NiAtMTA3LjA4NikiPgogICAgPGxpbmUgaWQ9IkxpbmVfMTAiIGRhdGEtbmFtZT0iTGluZSAxMCIgeDI9IjExIiB5Mj0iMTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNTQuNSAxMDguNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmOGM0MiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzExIiBkYXRhLW5hbWU9IkxpbmUgMTEiIHgxPSIxMSIgeTI9IjExIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjY1LjUgMTA4LjUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZjhjNDIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");
    background-repeat: no-repeat;
    background-position: right 0.8em top 50%;
    background-size: 1.3rem;
    padding-left: 1.1rem;
    padding-top: 0.6rem;
}