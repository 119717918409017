.steps-indicator{
    display: flex;
    flex-direction: column;

    &__index{
        color: $colour-primary;
        font-weight: bold;
        @include very-small-text-rfs;
    }
    &__boxes{
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
        display: flex;

        list-style: none;
        padding: 0;

        & li{
            height: 3px;
            background: $colour-primary;
            opacity: 0.2;
            width: 13%;

            &.done{
                opacity: 1;
            }
        }
    }
    &__hint{
        color: $colour-link;
        opacity: 0.7;
        font-weight: bold;
        @include small-text-rfs;
    }
}