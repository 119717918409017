.user-card{
    border-radius: 18px;
    background: white;
    border: 1px solid #D9D9D9;
    padding: 1.5rem;   
    //display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 20rem;
    

    &__img{
        //width: 65%;
        border-radius: 50%;
    }
    &__name{
        font-weight: bold;
        margin-top: 0.7rem;
        color: $colour-title;
        word-wrap: break-word;

        @include h4-rfs;
    }
    &__language{
        color: $colour-title;

        @include small-text-rfs;
    }
    &__description{
        margin-top: 1.3rem;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;

        @include small-text-rfs;
    }
    &__message{
        margin-top: 0.75rem;
        padding: .5rem 2rem;
    }

    &::after{
        content: attr(data-badge);
        position: absolute;
        top: 3%;
        right: 5%;
        padding: 1px 13px;
        border-radius: 18px;
        pointer-events: none;
        display: none;
        line-height: 1.1;
        @include very-small-text-rfs;
    }

    @include user-card-badge;
}

.owl-carousel-native{
    width: auto;

    .owl-item{
        @include media(small){
            max-width: 20rem;
        }

        & img {
            width: 65%;
        }
      
    }
}