doctor-card__details {
    margin: auto;
    padding-left: .5rem;
    padding-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .doctor-card__name {
    color: #535353;
    font-weight: bold;
    font-size: 1.3rem;
  }
  
  .doctor-card__name.text--alert {
    color: #E60A17;
  }
  
  .doctor-card__name.text--primary {
    color: #ff8c42;
  }
  
  .doctor-card__name.text--secondary {
    color: #21CECE;
  }
  
  .doctor-card__consultation {
    color: #B9B9B9;
    margin-top: 1.5rem;
  }
  
  .doctor-card__pricing-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: -0.2rem;
  }
  
  .doctor-card__pricing {
    color: #ff8c42;
    font-weight: bold;
    font-size: 1.4rem;
  }
  
  .doctor-card__pricing-separator {
    width: 0.3rem;
    height: 0.3rem;
    background-color: #B9B9B9;
    border-radius: 50%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .doctor-card__sector {
    color: #535353;
  }
  
  .doctor-card__agenda {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  @media screen and (max-width: 575px) {
    .doctor-card__agenda {
      display: none;
    }
  }
  
  .doctor-card__nav {
    font-weight: bold;
    border: none;
    background: none;
    color: #ff8c42;
    cursor: pointer;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
  }
  
  .doctor-card__nav:hover {
    color: #1b88a9;
  }
  
  .doctor-card__nav:active {
    border: none;
  }
  
  .doctor-card__nav:focus {
    border: none;
    outline: none;
  }
  
  .doctor-card__nav:visited {
    color: none;
  }
  
  .doctor-card__nav--disabled {
    cursor: default;
    color: silver;
  }
  
  .doctor-card__nav--disabled:hover {
    color: silver;
  }
  
  .doctor-card__schedule--collapsed {
    max-height: 18rem;
    overflow: hidden;
  }
  
  .doctor-card__day {
    text-align: center;
  }
  
  .doctor-card__day h4 {
    margin-bottom: 0;
    color: #535353;
    font-weight: bold;
  }
  
  .doctor-card ul {
    list-style: none;
    margin-top: 1.5rem;
    padding-left: 0;
  }
  
  .doctor-card li {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 1rem;
    background-color: #ff8c42;
    border: 1px solid transparent;
    padding: .1rem;
    color: #fff;
    border-radius: 5px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  
  .doctor-card li:hover {
    background-color: #fff;
    border: 1px solid rgba(34, 172, 213, 0.5);
  }
  
  .doctor-card li:hover .rdv-btn {
    color: #ff8c42;
  }
  
  .doctor-card .rdv-btn {
    color: #fff;
    font-weight: bold;
    width: 100%;
    height: 100%;
  }
  
  .doctor-card a {
    color: inherit;
  }
  
  .doctor-card a:hover {
    text-decoration: inherit;
  }
  
  .doctor-card__loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    justify-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .doctor-card__loader--loaded {
    display: none;
  }
  
  .doctor-card__more {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.75rem;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(60%, #fff), to(transparent));
    background: linear-gradient(0deg, #fff 60%, transparent 100%);
    display: -ms-grid;
    display: grid;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
  }
  
  .doctor-card__more--expanded {
    height: auto;
  }
  
  .doctor-card__more button {
    min-height: 1rem;
  }
  
  .doctor-card__loader {
    z-index: 9;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    background: #fff;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #ff8c42;
  }
  