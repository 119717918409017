.circle__img__profil{
    position: relative;
    width: 333px;
    height: 333px;
    background-color: #fff;
    border: 1px solid #e8e7e7;
    border-radius: 100%;
    margin: 10px;
}

.img__profil{
    top: 0%; left: 0%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 260px;
    height: 260px;
    background-size: cover;
    border-radius: 100%;
}

.profil__name{
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 50px;
    text-align: left;
    color: #ff8c42;

}

/*.chat__infos {

    background-color: #558bba;
    border-radius: 30px;

}

.chat-member__avatar {
    margin: 10px;
}

.chat-member__details {
    color: white;
}

.chat-member__name , .chat-member__age, .user-card__language{
    color: white !important;
}

.chat-member__status {
    color: #c1cec9 !important;
} */


.profil__mwandzani_ideal{
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    color: #ff8c42;
}

/*.app-message .profil__status{
    font-family: Ubuntu;
    font-weight: 300;
    font-size: 30px;
    text-align: left;
    color: #000;

}

/*.app-message .user-profile{
    background: #558bba;
    padding: 10px;
    border-radius: 20px;
}*/

/*.app-message .user-profile__avatar {
    padding: 10px;
}

.app-message .user-card__description {
    color: #fff;
}
.app-message .user-profile__label {
    color: azure;
}

.app-message .tags--third li {
    color:beige
}*/

.profil__description{
    width: 100% !important;
    height: auto;
    font-family: Ubuntu;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #000;

}
.track {
    stroke-width: 1;
    stroke: lightgrey;
    fill: none;
}

.progress {
    stroke-width: 10;
    stroke: rgb(255, 140, 66);
    stroke-linecap: round;
    fill: none;
    transform: rotate(270deg);
    transform-origin: center;
}

.percentage{
     width: 232px;
    height: 232px;
    background-color: rgba(255, 140, 60, 0.2);
    filter: drop-shadow(0px 13px 16px rgba(0, 19, 44, 0.17));
    border-radius: 100%;
    border: 1px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.percentage :nth-child(1){
    font-family: "Faune Text";
    font-weight: normal;
    font-size: 75px;
    line-height: 24px;
    text-align: left;
    color: #000;
    margin-bottom: 28px;
    padding-top: 20px;
}
.percentage :nth-child(2){
    width: 140px;
    font-family: "Faune Text";
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000;
}

.btn__action a, .btn__langues a{
    border-radius: 5px;
    background: transparent;
    border: 1px solid #ff8c42;
    padding: 15 auto;
    color: #ff8c42;
    font-family: "Ubuntu";
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: 24px;
    margin-right: 05px;
}

.btn__action a:hover, .btn__langues a:hover{
    color: #ff8c42;
}

hr{
    width: 100%;
    height: 0px;
    /* background: transparent; */
    border: 0.5px solid rgba(0, 0, 0, 0.16);
   
}
table{
    margin-bottom: 0px !important;
}
.title__identite{
    font-size: 25px;
    font-weight: bold;
    font-family: ubuntu;
    line-height: 24px;
    padding-top: 0;
    margin-bottom: 30px;
    color: #000;
}


.info__details__profil{
    background-color: #fff;
    width: 100vh;
    margin-top: 25px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0;
}


.nav{
    padding: 30px 0 30px 34px ;
    margin: auto
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #ff8c42 !important;
    background-color: transparent !important;
}

.nav-link, .list-group-item {

    font-family: Ubuntu;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: #696969;
}

.login-link, .list-group-item {

    font-family: Ubuntu;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: #696969;
}
.nav-link:hover{
    color: #ff8c42;
}
.nav_active {
    color: #ff8c42;
}


.tab-content th{
    font-family: ubuntu;
    font-size: 18px;
    font-weight: 500;
    color: #626262;
    padding: .5rem 0rem ;
    padding-left: 15px !important;
    padding-top: 30px;
}
.items{
    margin: 05px auto 05px 39px ;
}

.items__icons{
    max-width: 48px;
    margin-right: 35px;
}

.items__questions{
    font-family: Ubuntu;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #000;

}

.items__result{
    font-family: Ubuntu;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #ff8c42;
}

.items__icon__check{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    max-width: 35px;
}

 /*tr td:nth-child(1){
    border-right: 1px solid #000 !important;
}
tr th:nth-child(1){
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
}
tr th:nth-child(2){
    border-bottom: 1px solid #000 !important;
}
*/

tr td:nth-child(2){
   width: 15%;
  
}

.mt-24{
    margin-top: 24px;
}

.bloc_detail_profil {
    margin : 10px !important;
    padding: 0 !important;
}

.nav-detail-profil .nav-link {
    margin-left: 10% !important;

}

.nav-detail-profil .list-group-item {
    text-decoration: none;
}

#nav-pills-desktop {
    height: 100%;
}

.nav-detail-profil .list-group-item:active {
    color : white;
    background-color : #ff8c42;
}

#detail-profil {
    margin-top: 50px
}
/* css sonyah */
.btn-activity-profil{
	width: -webkit-fill-available;
	height: 58px;
	padding-top: 16px !important;
}
.btn-activity-profil-new{
	width: -webkit-fill-available;
}
.img-top-profil{
	height: 140px !important;
}
.card-body-profil{
	height:98px !important;
}
.btn-home{
		padding: 12px 12px !important; 
		font-size: 1.3rem;
		width:31%;
	}

  .inscription-page__submit button {
 /* width: 60% !important;*/
  max-width: 16rem;
  padding:12px;
}
.login-page__login {
    font-weight: bold;
    color: #9B9B9B;
    font-size: 1.1rem;
    width: 63% !important;
	padding:12px !important;
}
.user-card__message {
    margin-top: 0.75rem;
    /* padding: 0.5rem 2rem; */
    padding: 7px 29px !important;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0px !important;
}
.card-body-profil a{
	margin-top:23px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	background-color:#FF6600 !important;
	color:white !important;
}
.nav-tabs a{
	/*width:25% !important;*/
	text-align:center;
}
.th-table{
	border:none !important;
}

@media (min-width: 320px) and (max-width: 480px) {
	.btn-activity-profil-new{
		height: 58px;
		padding-top: 16px !important;
	}
	.btn-new-big{
		padding-top:6px !important;
	}
	.user-status-online {
    position: relative;
    float: right;
    right: 53px !important;
    bottom: 0;
    top: 168px !important;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #419D78;
    border: 3px solid white;
    border-radius: 50%;
	}
	.user-status {
    position: relative;
    float: right;
    right: 53px !important;
    bottom: 0;
    top: 168px !important;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #d7bcbc;
    border: 3px solid white;
    border-radius: 50%;
	}
	.btn-home{
		padding: 12px 12px !important; 
		font-size: 1.3rem;
		width:57%;
	}
	.custom-btn--outline {
		color: $colour-primary;
		background: transparent;
		border: 1px solid $colour-primary;
		width: 157px !important;
	}
	.card-body-profil a{
		width: auto;
	}
	

}
@media (min-width: 320px) and (max-width: 320px){
	
	.user-status-online {
		top:-31px !important;
		
		right: 34px !important;

	}
	.user-status {
		top:-31px !important;
		
		right: 34px !important;
	}
}
	


/* css sonyah end */
