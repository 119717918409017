.inscription-page{
    $self: &;
    @include full-height;

    
    &__fixed{
        position: fixed;
        top: 2rem;
        left: 2rem;
    }
    &__logo{
        width: 5.5rem;
    }
    &__wrapper-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__home{
        & a:hover {
            text-decoration: none;
        }
        & span{
            color: $colour-link;
            font-weight: bold;
            @include p-rfs;
        }
    }
    &__bg{
        position: fixed;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;


        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            /*z-index: -1;*/
            width: 100%;
            height: 100%;
            background: $colour-primary;
            opacity: 0.8;
        }
        
        @include inscription-bg;

        &--center{
            background-position: center;
        }
        &--login{
            left: 50%;
            z-index: -1;
        }
    }
    &__content{
        margin-top: 13vh;
        width: 100%;
        //overflow: hidden;

        &--small-margin{
            margin-top: 5vh;
        }
    }
    &__form{
        display: flex;
        width: 700%;
    }
    &__page{
        width: 14.29%;
        transition: margin-left 0.3s ease-in-out;
        padding-left: 3px;
        padding-right: 3px;
        
        &--overflow{
            max-height: 50vh;
        }
    }
    &__slide{
        margin-left: 0; 
    }
    &__img-container{
        width: 50vmin;
        height: 50vmin;
        max-width: 15rem;
        max-height: 15rem;
        border-radius: 50%;
        border: 1px solid gray;
        overflow: hidden;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            & #{$self}__img{
                transform: scale(1.05);
            }
        }
    }
    &__img{
        width: 100%;
        transition: transform 0.3s ease-out;

        &--initial{
            width: 25%;
        }
    }
    &__upload{
        display: none;
    }
    &__submit{
        display: flex;
        justify-content: center;

        & button{
            width: 60%;
            max-width: 16rem;
        }
    }
}
