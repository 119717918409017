.custom-nav{
    z-index: 100;

    & .nav-link{
        padding-left: 1rem !important;
        padding-right: 1rem !important;

        @include media(medium){
            margin-bottom: 1rem;
        }
    }
    & .navbar-collapse{
        @include media(medium){
            background-color: white;
            border-bottom: 1px solid #eaeaea;
        } 
    }
    & .navbar-toggler{
        border: none !important;
        outline: none;

        &:focus{
            outline: none;
        }
    }
    & .navbar-toggler-icon{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAxNiI+CiAgPGc+CiAgICA8cGF0aCBkPSJNMjQsMkgxQS45LjksMCwwLDEsMCwxLC45LjksMCwwLDEsMSwwSDI0YS45LjksMCwwLDEsMSwxQS45LjksMCwwLDEsMjQsMloiIGZpbGw9IiM3MDcwNzEiLz4KICAgIDxwYXRoIGQ9Ik0yNCw5SDFBLjkuOSwwLDAsMSwwLDgsLjkuOSwwLDAsMSwxLDdIMjRhLjkuOSwwLDAsMSwxLDFBLjkuOSwwLDAsMSwyNCw5WiIgZmlsbD0iIzcwNzA3MSIvPgogICAgPHBhdGggZD0iTTI0LDE2SDUuNWExLDEsMCwwLDEsMC0ySDI0YTEsMSwwLDAsMSwwLDJaIiBmaWxsPSIjNzA3MDcxIi8+CiAgPC9nPgo8L3N2Zz4K") !important;
    }
}


.home-nav{
    $self: &;
    position: fixed; 
    z-index: $navbar-z-index;
    //width: map-get($map: $nav-logged-padding, $key: xl);

    background-color: $colour-search-wrapper /*white*/;

    display: flex;
    flex-direction: column;

    @include media(large){
        width: map-get($map: $nav-logged-padding, $key: lg);
    }
    @include media(medium){
        width: map-get($map: $nav-logged-padding, $key: md);
    }

    @include media-min-width(small){
        top: 0;
        left: 0;
        height: 100%;
    }
    @include media(small){
        bottom: 0;
        left: 0;
        width: 100%;

        flex-direction: row;
    }


    &__logo{
        display: flex;
        justify-content: center;

    }
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
    }
    &__wrapper{
        height: 100%;
    }
    &__menu{
        color: $colour-title;
        font-weight: bold;
        @include p-rfs;
    }
    &__links{  
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        @include p-rfs;

        & ul {
            display: flex;
            flex-direction: column;
            list-style: none;

            @include media(small){
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
        }
        & i {
            color: #909090;
            font-size: 1.3rem;
            transition: .2s ease-out;
        }
        & a{
            color: $colour-text;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: .2s ease-out;

            &:hover{
                text-decoration: none;
            }
        }
        & span {
            margin-left: 0.8rem;

            @include media(large){
                margin-left: 0.6rem;
            }
        }
        & .active{
            border-right: 3px solid $colour-primary;

            @include media(small){
                border: none;
            }

            & a {
                font-weight: bold;
                color:  $colour-primary;
            }
            & i {
                color: $colour-primary;
            }

            & .icon-profil {
                &:before {
                  content: $icon-profilActive; 
                }
              }
              .icon-actualite {
                &:before {
                  content: $icon-actualiteActive; 
                }
              }
              
              & .icon-conversation {
                &:before {
                  content: $icon-conversationActive; 
                }
              }
              
              &.icon-exercice {
                &:before {
                  content: $icon-exerciceActive; 
                }
              }
              
              & .icon-gratitude {
                &:before {
                  content: $icon-gratitudeActive; 
                }
              }
              
              & .icon-language {
                &:before {
                  content: $icon-languageActive; 
                }
              }
              
              & .icon-story {
                &:before {
                  content: $icon-storyActive; 
                }
              }
            
        }
        & .Active{
            border-right: 0 solid #696969;

            @include media(small){
                border: none;
            }

            & a {
                font-weight: bold;
                color:  #696969;
            }
            & i {
                color: #696969;
            }
            
        }
    }
    &__icon{
        display: inline-block;
        width: 2.5rem;
        text-align: center;
    }
    &__profile{
        cursor: pointer;

        & #{$self}__icon{
            padding: 0.25rem;
            border-radius: 50%;
            border: 1px solid #E8E7E7;
            box-sizing: border-box;
        }

        & img{
            width: 100%;
            border-radius: 50%;
        }
    }
    &__pseudo{
        margin-left: 0.5rem;
        font-weight: bold;
        color: $colour-title;
    }
}