
    .contenu {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .contenu .btn-orange {
        background: #f0651a;
        color: #fff;
        display: block;
        width: 100%;
        font-size: 20px;
        margin-top: 5rem;
    }
    .contenu header {
      margin-bottom: 50px;
    }

    .Mybutton{
        background-color: #ff6600;
        color: #ffffff;
        width: 100%;
        height: 60px;
        font-weight: bold;
        border: none;
        font-size:17px;
        border-radius: 5px;
    }
    
    body {
        background-color: #e2e2e2
    }
    
    .auth {
        display: flex;
        flex-direction: row;
        background-color: white;
        border: solid 1px rgb(226, 226, 226);
        border-radius: 5px;
        padding: 30px;
        width: 100%;
        height: 50px;
        align-items: center;
        margin-top: 30px;
        justify-content: space-around;
    }
    
    .profileImage {
        width: 100px;
        height: 100px;
        background-size: cover;
        border-radius: 50%;
    }
    
    .profileData {
        height: 80px;
        border: solid 1px rgb(224, 224, 224);
        border-radius:  15px;
        background-color: white;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 10px;
        padding-right:10px;
        margin-top: 10px;
    }
    
    .logo {
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 100px;
        width: 100px;
    }