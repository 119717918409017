@media (min-width: 576px){
}
.modal-body {
    padding: 40px 20px 40px 20px !important;
}
.availability-form {
    min-width: 100%;
    display: flex;
    justify-content: space-around;
}

.item-title {
    background: #21cece;
    padding: 5px;
    text-align: center;
}


.availability-item {
    min-width: 15%;    
}

.item-content {
    border: solid 1px #ced4da;
    border-top: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.custom-switch {
    margin-top: 8px !important;
}
.btn{
    padding: .375rem .75rem;
   /* margin: 2% auto;*/
}

.btn-secondary {
    background-color: #21cece;
}

@media screen and (max-width: 991px) {
    .availability-form {
        flex-direction: column;
    }
    .availability-item{
        margin-top: 10px;
    }
}


.custom-calendar .slot{
    background-color: #f8f9fa;
    border-radius: 1rem;
    padding: 1rem 2rem 1rem 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.custom-calendar .slot .type,
.custom-calendar .legende .type {
    width: 20px;
    height: 20px;
    background: #21cece;
    border-radius: 50%;
}
.custom-calendar .legende .type {
    width: 10px;
    height: 10px;
}
.custom-calendar .slot .type.online ,
.custom-calendar .legende .type.online {
    background: #a3da4f;
}
.custom-calendar .tab-content > .active {
    min-height: unset !important;
}
.custom-calendar .legende {
    display: flex;
    align-items: center;
}
.custom-calendar .legende label{
    padding-bottom: unset !important;
    margin-bottom: unset !important;
    margin-left: 15px;
}
.input-time-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.availability-form .modal-content{
    width: 50vw;
    padding-right: 1rem;
}