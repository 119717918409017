*, 
*::before, 
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body{
    font-weight: normal;
    font-size: 20px;
}

::selection{
    color: #fff;
    background: $colour-primary;
}