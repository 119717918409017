.custom-form{
    color: darken($colour-text, 20%);
    padding: 1.5rem;
    border-radius: 13px;
    
    &::placeholder{
        color: #C9C9C9;
    }
    &:focus{
        color: $colour-link;
        outline: none;
        box-shadow: none;
        border-color: #ced4da;
    }

    &__password-wrapper{
        width: 100%;
        height: 100%;
        position: relative;

        & input[type="password"]{
            padding-right: 4rem;
        }
    }
    &__password-toogler{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4rem;
        cursor: pointer;

        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNS44MjgiIGhlaWdodD0iMzUuODI4IiB2aWV3Qm94PSIwIDAgMzUuODI4IDM1LjgyOCI+CiAgPGcgaWQ9Ikljb25fZmVhdGhlci1leWUtb2ZmIiBkYXRhLW5hbWU9Ikljb24gZmVhdGhlci1leWUtb2ZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4wODYgLTAuMDg2KSI+CiAgICA8cGF0aCBpZD0iUGF0aF8xMDMiIGRhdGEtbmFtZT0iUGF0aCAxMDMiIGQ9Ik0yNi45MSwyNi45MUExNS4xMDUsMTUuMTA1LDAsMCwxLDE4LDMwQzcuNSwzMCwxLjUsMTgsMS41LDE4QTI3LjY3NSwyNy42NzUsMCwwLDEsOS4wOSw5LjA5bTUuNzYtMi43M0ExMy42OCwxMy42OCwwLDAsMSwxOCw2QzI4LjUsNiwzNC41LDE4LDM0LjUsMThhMjcuNzUsMjcuNzUsMCwwLDEtMy4yNCw0Ljc4NW0tMTAuMDgtMS42YTQuNSw0LjUsMCwxLDEtNi4zNi02LjM2IiBmaWxsPSJub25lIiBzdHJva2U9IiNiNWI1YjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgICA8cGF0aCBpZD0iUGF0aF8xMDQiIGRhdGEtbmFtZT0iUGF0aCAxMDQiIGQ9Ik0xLjUsMS41bDMzLDMzIiBmaWxsPSJub25lIiBzdHJva2U9IiNiNWI1YjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.75rem;

        &--visible{
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIyNiIgdmlld0JveD0iMCAwIDM1IDI2Ij4KICA8ZyBpZD0iSWNvbl9mZWF0aGVyLWV5ZSIgZGF0YS1uYW1lPSJJY29uIGZlYXRoZXItZXllIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC41IC01KSI+CiAgICA8cGF0aCBpZD0iUGF0aF8xMDUiIGRhdGEtbmFtZT0iUGF0aCAxMDUiIGQ9Ik0xLjUsMThTNy41LDYsMTgsNiwzNC41LDE4LDM0LjUsMTgsMjguNSwzMCwxOCwzMCwxLjUsMTgsMS41LDE4WiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYjViNWI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTA2IiBkYXRhLW5hbWU9IlBhdGggMTA2IiBkPSJNMjIuNSwxOEE0LjUsNC41LDAsMSwxLDE4LDEzLjUsNC41LDQuNSwwLDAsMSwyMi41LDE4WiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYjViNWI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogIDwvZz4KPC9zdmc+Cg==");
        }
    }

    &__search-wrapper, &__send-wrapper{
        width: 100%;
        height: 100%;
        position: relative;

        & input{
            padding-right: 4rem;
            border: 1px solid $form-border-color;

            &::placeholder{
                color: $colour-link;
                @include small-text-rfs;
            }
        }
    }
    &__search-submit{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4rem;
        cursor: pointer;

        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ni42IDQ2LjYiPgogIDxnPgogICAgPHBhdGggZD0iTTIxLDQxLjlBMjEsMjEsMCwxLDEsNDEuOSwyMSwyMSwyMSwwLDAsMSwyMSw0MS45Wk0yMSw0QTE3LDE3LDAsMSwwLDM3LjksMjEsMTcsMTcsMCwwLDAsMjEsNFoiIGZpbGw9IiNmNDhiNDYiLz4KICAgIDxwYXRoIGQ9Ik00NC42LDQ2LjZhMS45LDEuOSwwLDAsMS0xLjQtLjVMMzIuOSwzNS44YTIuMSwyLjEsMCwxLDEsMi45LTIuOUw0Ni4xLDQzLjJhMi4zLDIuMywwLDAsMSwwLDIuOUEyLDIsMCwwLDEsNDQuNiw0Ni42WiIgZmlsbD0iI2Y0OGI0NiIvPgogIDwvZz4KPC9zdmc+Cg==");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.25rem;
        background-color: transparent;
        border: none;
        outline: none;
    }
    &__send-wrapper{
        & input{
            background-color: $bg-page-logged;

            &:focus{
                background-color: $bg-page-logged;
            }
        }
    }
    &__send-submit{
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);

        height: 2.3rem;
        width: 2.3rem;

        background-color: $colour-primary;
        border-radius: 50%;
        box-shadow: 0 3px 3px lighten($color: $colour-primary, $amount: 20%);
        border: none;
        outline: none;

        text-align: center;
        font-size: 1.2rem;
        padding-top: 0.3rem;
        color: white;
        padding-right: 0.1rem;
        
    }
}

.input-long{
    color: #363636;
    /*padding: 1.5rem;*/
    height: 3rem;
    border-radius: 13px;
}