@mixin media($breakpoint) {
  @media screen and (max-width: map-get($map: $breakpoints, $key: $breakpoint)) {
    @content;
  }
}

@mixin media-min-width($breakpoint) {
  @media screen and (min-width: map-get($map: $breakpoints, $key: $breakpoint) + 1) {
    @content;
  }
}

@mixin full-height {
  height: 100vh;
  min-height: $min-height;
}

@mixin colour-modifiers {
  &--default {
    color: $colour-title;
  }
  &--white {
    color: white;
  }
  &--primary {
    color: $colour-secondary;
  }
  &--secondary {
    color: $colour-primary;
  }
  &--third {
    color: $colour-fourth;
  }
  &--fourth {
    color: $colour-third;
  }
}

//    Font size responsive
@mixin h1-rfs {
  font-size: 5.5rem;
  line-height: 1.1;

  @include media(extra-large) {
    font-size: 4.3rem;
  }
  @include media(small) {
    font-size: 3.75rem;
  }
  @include media(extra-small) {
    font-size: 2.35rem;
  }
  @include media(tiny) {
    font-size: 2.1rem;
  }
}

@mixin h2-rfs {
  font-size: 3rem;

  @include media(extra-large) {
    font-size: 2.5rem;
  }

  @include media(small) {
    font-size: 2rem;
  }

  @include media(extra-small) {
    font-size: 1.75rem;
  }
}

@mixin h3-rfs {
  font-size: 2rem;

  @include media(extra-large) {
    font-size: 1.7rem;
  }

  @include media(small) {
    font-size: 1.4rem;
  }
}

@mixin h4-rfs {
  font-size: 1.3rem;

  @include media(extra-large) {
    font-size: 1.2rem;
  }

  @include media(small) {
    font-size: 1.1rem;
  }

  @include media(extra-small) {
    font-size: 1rem;
    line-height: 1.7;
  }
}

@mixin h5-rfs {
  font-size: 1.25rem;

  @include media(large) {
    font-size: 1rem;
  }
}

@mixin h6-rfs {
  //font-size: 1.15rem;
  font-size: 1.2rem;

  @include media(large) {
    font-size: 1rem;
  }
}

@mixin p-rfs {
  font-size: 1.2rem;

  @include media(large) {
    font-size: 0.9rem;
  }
}

@mixin small-text-rfs {
  font-size: 1.1rem;
}

@mixin very-small-text-rfs {
  font-size: 0.8rem;
}

@mixin chat-rfs {
  font-size: 1.1rem;

  @include media(small) {
    font-size: 0.95rem;
  }
}

@mixin tag {
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  margin-right: 0.4rem;
  @include p-rfs;
}

@mixin tag-small {
  padding: 0.3rem 1rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  @include small-text-rfs;
}

@mixin inscription-bg {
  &--1 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 1
      ));
  }
  &--2 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 2
      ));
  }
  &--3 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 3
      ));
  }
  &--4 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 4
      ));
  }
  &--5 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 5
      ));
  }
  &--6 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 6
      ));
  }
  &--7 {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: 7
      ));
  }
  &--login {
    background-image: url(base-url("../images/bg/") + get-inscription-bg(
        $key: login
      ));
  }
}

@mixin user-card-badge {
  @each $badge, $properties in $user-badge {
    &--#{$badge}::after {
      display: block;
      background-color: map-get($map: $properties, $key: background-color);
      color: map-get($map: $properties, $key: color);
      border: map-get($map: $properties, $key: border);
    }
  }
}

@mixin formation-card-badge {
  @each $badge, $properties in $formation-badge {
    &--#{$badge}::after {
      display: block;
      background-color: map-get($map: $properties, $key: background-color);
      color: map-get($map: $properties, $key: color);
      border: map-get($map: $properties, $key: border);
    }
  }
}
