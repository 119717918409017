.tags{
    $self: &;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & li {
        @include tag;
    }

    &--secondary{
        & li{
            background-color: rgba($colour-primary, 0.2); 
            color: $colour-primary;
        }
    }
    &--third{
        & li{
            background-color: rgba($colour-fourth, 0.2); 
            color: $colour-fourth;
        }
    }
    &--small{
        & li {
            @include tag-small;
        }
    }

}