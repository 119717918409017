.accomplishment{
    $self: &;
    &__wrapper{
        width: 100%;
        background-color: white;
        border-radius: $form-radius;
        padding: 0.8rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        &--secondary{
            & #{$self}__activity-icon{
                color: $colour-primary;
                background-color: rgba($color: $colour-primary, $alpha: 0.2);
            }
            & #{$self}__value{
                color: $colour-primary;
            }
        }
        &--third{
            & #{$self}__activity-icon{
                color: $colour-fourth;
                background-color: rgba($color: $colour-fourth, $alpha: 0.2);
            }
            & #{$self}__value{
                color: $colour-fourth;
            }
        }
        &--long{
            flex-direction: column;
            align-items: flex-start;
            padding: 1.3rem 1.5rem 1rem;
        }
    }
    &__activity-icon{
        border-radius: 50%;
        background-color: rgba($color: $colour-primary, $alpha: 0.2);
        width: 3rem;
        height: 3rem;

        display: flex;
        justify-content: center;
        align-items: center;
        color: $colour-primary;
        font-size: 1.2rem;
    }
    &__values{
        margin-left: 0.5rem;
    }
    &__value{
        display: block;
        color: $colour-primary;
        font-weight: bold;
        line-height: 1;
        @include h3-rfs;
    }
    &__label{
        display: block;
        color: $colour-link;
        @include very-small-text-rfs;
    }
    &__title{
        color: $colour-primary;

        & i {
            font-size: 1.25rem;
        }
        & span {
            margin-left: 0.5rem;
            font-weight: bold;
            @include h6-rfs;
        }
    }
    &__description{
        margin-top: 0.5rem;
        @include small-text-rfs;
    }
    &__list{
        margin-top: 0.5rem;
        list-style: none;
        @include small-text-rfs;

        & li{
            display: flex;
            align-items: center;
            margin-top: 0.75rem;

            &::before{
                content: "";
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                display: inline-block;
                margin-right: 0.5rem;
                background-color: rgba($color: $colour-primary, $alpha: 0.2);
                background-repeat: no-repeat;
                background-size: 30%;
                background-position: center;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA2IDYiPgogIDxjaXJjbGUgaWQ9IkVsbGlwc2VfNjAiIGRhdGEtbmFtZT0iRWxsaXBzZSA2MCIgY3g9IjMiIGN5PSIzIiByPSIzIiBmaWxsPSIjZmY4YzQyIi8+Cjwvc3ZnPgo=");
            }

            &.achieved{
                &::before{
                    background-color: rgba($color: $colour-fourth, $alpha: 0.2);
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC42MjEiIGhlaWdodD0iMTAuOTAzIiB2aWV3Qm94PSIwIDAgMTQuNjIxIDEwLjkwMyI+CiAgPHBhdGggaWQ9Ikljb25fYXdlc29tZS1jaGVjayIgZGF0YS1uYW1lPSJJY29uIGF3ZXNvbWUtY2hlY2siIGQ9Ik00Ljk2NiwxNS4yNjYuMjE0LDEwLjUxNGEuNzMxLjczMSwwLDAsMSwwLTEuMDM0TDEuMjQ4LDguNDQ3YS43MzEuNzMxLDAsMCwxLDEuMDM0LDBsMy4yLDMuMiw2Ljg1Ni02Ljg1NmEuNzMxLjczMSwwLDAsMSwxLjAzNCwwbDEuMDM0LDEuMDM0YS43MzEuNzMxLDAsMCwxLDAsMS4wMzRMNiwxNS4yNjZBLjczMS43MzEsMCwwLDEsNC45NjYsMTUuMjY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtNC41NzcpIiBmaWxsPSIjNDE5ZDc4Ii8+Cjwvc3ZnPgo=");
                    background-size: 50%;
                }

                & b{
                    color: $colour-fourth;
                }
            }
        }
        & b {
            color: $colour-primary;
            margin-left: 0.3rem;
        }
    }
}